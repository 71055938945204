@keyframes sound {
  0%{
    transform: scaleY(1);
    opacity: .9;
  }
  50%{
    transform: scaleY(1.1);
    opacity: 1;
  }
  100%{
    transform: scaleY(1);
    opacity: .9;
  }
}

header{
  padding: 30px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  @media #{$max-width-mobile} {
    padding: 2.7vw 0;
  }
  .container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    @media #{$max-width-mobile} {
      padding: 0 2.7vw;
    }
  }
    .social-list{
      @include clearfix;
      position: relative;
      padding-left: 25px;
      margin-left: 25px;
      &:before{
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: white;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -3px;
        opacity: 0;
        [data-quiz="true"] &{
          opacity: 1;
        }
        [data-finish="true"] &{
          opacity: 0;
        }
      }
      li{
        float: left;
        & + li{
          margin-left: 8px;
        }
      }
      a{
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 3px solid white;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        @extend %link-hover-transition;
        &:hover{
          border-color: $color-brown;
          color: $color-brown;
        }
      }
    }
}
.sound-toggle{
  font-size: 16px;
  text-transform: uppercase;
  font-family: $font-family--OpenSans;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 8px;
  pointer-events: none;
  opacity: 0;
  transition-duration: .3s;
  [data-quiz="true"] &{
    pointer-events: all;
    opacity: 1;
    transition-duration: .3s;
  }
  [data-finish="true"] &{
    pointer-events: none;
    opacity: 0;
    transition-duration: .3s;
  }
  @media #{$max-width-mobile} {
    margin-left: 0;
  }
  .icon-sound-bar{
    box-sizing: border-box;
    height: 36px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-left: 8px;
    @media #{$max-width-mobile}{
      height: 6.67vw;
      margin-left: 0;
    }
    &:hover{
      cursor: pointer;
    }
    &:before,&:after{
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 3px;
      background: white;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      pointer-events: none;
      opacity: 0;
      transition-duration: .3s;
      @media #{$max-width-mobile}{
        width: 1vw;
        height: 1vw;
        border-radius: 0.5vw;
      }
    }
    &:before{
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after{
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  //&:hover{
  //  .sound-bar{
  //    animation-play-state: paused;
  //  }
  //}
  .sound-bar{
    width: 5px;
    border-radius: 3px;
    background: white;
    height: 100%;
    transition-duration: .3s;
    animation: sound 1s infinite;
    will-change: transform opacity;
    @media #{$max-width-mobile}{
      width: 1vw;
    }
    & + .sound-bar{
      margin-left: 5px;
      @media #{$max-width-mobile}{
        margin-left: 1vw;
      }
    }
    &:nth-child(1), &:nth-child(4){
      height: 63%;
    }
    &:nth-child(2), &:nth-child(5){
      height: 38%;
    }
    @for $i from 1 through 5{
      &:nth-child(#{$i}){
        animation-delay: $i*0.1s;
        animation-duration: random($i)/100 + 1s;
      }
    }
  }
  &[data-sound="off"]{
    .icon-sound-bar{
      &:before,&:after{
        width: 35px;
        opacity: 1;
        transition-duration: .3s;
        @media #{$max-width-mobile}{
          width: 8vw;
        }
      }
    }
    .sound-bar{
      animation: none;
      height: 5px;
      transition-duration: .3s;
      @media #{$max-width-mobile}{
        height: 1vw;
      }
    }
  }
}