$icomoon-font-family: "chiens" !default;
$icomoon-font-path: "../fonts" !default;

$icon-son: "\e900";
$icon-headphones: "\e901";
$icon-arrow: "\e902";
$icon-whatsapp: "\e903";
$icon-instagram: "\e904";
$icon-check: "\ea10";
$icon-facebook: "\ea90";
$icon-twitter: "\ea96";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
  url('#{$icomoon-font-path}/#{$icomoon-font-family}/#{$icomoon-font-family}.ttf?2kp6cw') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}/#{$icomoon-font-family}.woff?2kp6cw') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}/#{$icomoon-font-family}.svg?2kp6cw##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-son {
  &:before {
    content: $icon-son;
  }
}
.icon-headphones {
  &:before {
    content: $icon-headphones;
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
