@charset "UTF-8";
@import url(normalize.css);
@media (min-width: 1025px) {
  header .social-list a, .quiz .input-wrapper, .footer .ml-link, .popup .close:hover, .popup a, .cookie-block .link-block a {
    transition-duration: .5s; }
    header .social-list a:hover, .quiz .input-wrapper:hover, .footer .ml-link:hover, .popup .close:hover, .popup a:hover, .cookie-block .link-block a:hover {
      transition-duration: .5s; } }

@font-face {
  font-family: "chiens";
  src: url("../fonts/chiens/chiens.ttf?2kp6cw") format("truetype"), url("../fonts/chiens/chiens.woff?2kp6cw") format("woff"), url("../fonts/chiens/chiens.svg?2kp6cw#chiens") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "chiens" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-son:before {
  content: ""; }

.icon-headphones:before {
  content: ""; }

.icon-arrow:before {
  content: ""; }

.icon-whatsapp:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

@font-face {
  font-family: "Plumpfull";
  src: url("../fonts/Plumpfull/Plumpfull-400-normal.eot");
  src: url("../fonts/Plumpfull/Plumpfull-400-normal.eot?#iefix") format("embedded-opentype"), url("../fonts/Plumpfull/Plumpfull-400-normal.woff") format("woff"), url("../fonts/Plumpfull/Plumpfull-normal.ttf") format("truetype");
  font-style: normal;
  font-weight: 400; }

html,
body {
  line-height: 1.35;
  margin: 0;
  font-family: "Asap", sans-serif;
  min-height: 100vh;
  color: black;
  font-size: 20px;
  height: 100vh;
  overflow: hidden; }
  @media all and (max-width: 1024px) {
    html,
    body {
      font-size: 18px; } }
  @media all and (max-width: 767px) {
    html,
    body {
      font-size: 4.7vw; } }

html {
  overflow-x: hidden; }
  html.is-open-popup {
    overflow: hidden;
    height: 100%;
    max-height: 100%; }
    html.is-open-popup body > * {
      filter: blur(10px);
      transition-duration: .3s; }
    html.is-open-popup body .popup--wrapper {
      filter: blur(0);
      transition-duration: 0s; }

body {
  width: 100%;
  box-sizing: border-box;
  background-image: url("../img/bg-intro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white; }
  @media all and (max-width: 767px) {
    body {
      overflow: auto; } }
  body.is-open-popup > * {
    filter: blur(100px);
    transition-duration: .3s; }
  body[data-device="iPad"] {
    height: auto; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1.25; }

.clearfix:after {
  content: '';
  display: table;
  width: 100%;
  height: 0;
  clear: both;
  opacity: 0; }

.text-normal {
  font-weight: normal; }

.text-center {
  text-align: center; }

b, strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: white; }

ul {
  padding: 0;
  margin: 0; }
  ul li {
    list-style: none; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

button, a, * {
  outline: none; }

header, footer, section {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0; }
  header .container, footer .container, section .container {
    padding: 0 35px;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box; }
    @media all and (max-width: 1919px) {
      header .container, footer .container, section .container {
        padding: 0 3vw; } }
    @media all and (max-width: 767px) {
      header .container, footer .container, section .container {
        padding: 0 4vw; } }

form {
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden; }
  [data-device="iPad"] form {
    width: 1024px;
    overflow-y: auto;
    overflow-x: hidden; }
  @media all and (max-width: 767px) {
    form {
      height: auto; } }

img {
  display: block; }

@media (min-width: 768px) {
  .mobile-visible {
    display: none !important; } }

@media (max-width: 767px) {
  .mobile-hidden {
    display: none !important; } }

@media (min-width: 1024px) {
  .tablet-visible {
    display: none !important; } }

@media (max-width: 1023px) {
  .tablet-hidden {
    display: none !important; } }

.hidden-all {
  display: none !important; }

@keyframes fadeIn {
  0% {
    filter: blur(100px);
    opacity: 0;
    transform: scale(1.1); }
  100% {
    filter: blur(0);
    opacity: 1;
    transform: scale(1); } }

@keyframes fadeOut {
  0% {
    filter: blur(0);
    opacity: 1;
    transform: scale(1); }
  100% {
    filter: blur(100px);
    opacity: 0;
    transform: scale(1.1);
    pointer-events: none; } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes translate {
  0% {
    transform: translate(2%, 3%); }
  15% {
    transform: translate(4%, 0%); }
  30% {
    transform: translate(1%, 2%); }
  60% {
    transform: translate(3%, 5%); }
  100% {
    transform: translate(2%, 3%); } }

@keyframes sound {
  0% {
    transform: scaleY(1);
    opacity: .9; }
  50% {
    transform: scaleY(1.1);
    opacity: 1; }
  100% {
    transform: scaleY(1);
    opacity: .9; } }

header {
  padding: 30px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1; }
  @media all and (max-width: 767px) {
    header {
      padding: 2.7vw 0; } }
  header .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px; }
    @media all and (max-width: 767px) {
      header .container {
        padding: 0 2.7vw; } }
  header .social-list {
    position: relative;
    padding-left: 25px;
    margin-left: 25px; }
    header .social-list:after {
      content: '';
      display: table;
      width: 100%;
      height: 0;
      clear: both;
      opacity: 0; }
    header .social-list:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: white;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -3px;
      opacity: 0; }
      [data-quiz="true"] header .social-list:before {
        opacity: 1; }
      [data-finish="true"] header .social-list:before {
        opacity: 0; }
    header .social-list li {
      float: left; }
      header .social-list li + li {
        margin-left: 8px; }
    header .social-list a {
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 3px solid white;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-size: 18px; }
      header .social-list a:hover {
        border-color: #CCBFB6;
        color: #CCBFB6; }

.sound-toggle {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 8px;
  pointer-events: none;
  opacity: 0;
  transition-duration: .3s; }
  [data-quiz="true"] .sound-toggle {
    pointer-events: all;
    opacity: 1;
    transition-duration: .3s; }
  [data-finish="true"] .sound-toggle {
    pointer-events: none;
    opacity: 0;
    transition-duration: .3s; }
  @media all and (max-width: 767px) {
    .sound-toggle {
      margin-left: 0; } }
  .sound-toggle .icon-sound-bar {
    box-sizing: border-box;
    height: 36px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-left: 8px; }
    @media all and (max-width: 767px) {
      .sound-toggle .icon-sound-bar {
        height: 6.67vw;
        margin-left: 0; } }
    .sound-toggle .icon-sound-bar:hover {
      cursor: pointer; }
    .sound-toggle .icon-sound-bar:before, .sound-toggle .icon-sound-bar:after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 3px;
      background: white;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      pointer-events: none;
      opacity: 0;
      transition-duration: .3s; }
      @media all and (max-width: 767px) {
        .sound-toggle .icon-sound-bar:before, .sound-toggle .icon-sound-bar:after {
          width: 1vw;
          height: 1vw;
          border-radius: 0.5vw; } }
    .sound-toggle .icon-sound-bar:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .sound-toggle .icon-sound-bar:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
  .sound-toggle .sound-bar {
    width: 5px;
    border-radius: 3px;
    background: white;
    height: 100%;
    transition-duration: .3s;
    animation: sound 1s infinite;
    will-change: transform opacity; }
    @media all and (max-width: 767px) {
      .sound-toggle .sound-bar {
        width: 1vw; } }
    .sound-toggle .sound-bar + .sound-bar {
      margin-left: 5px; }
      @media all and (max-width: 767px) {
        .sound-toggle .sound-bar + .sound-bar {
          margin-left: 1vw; } }
    .sound-toggle .sound-bar:nth-child(1), .sound-toggle .sound-bar:nth-child(4) {
      height: 63%; }
    .sound-toggle .sound-bar:nth-child(2), .sound-toggle .sound-bar:nth-child(5) {
      height: 38%; }
    .sound-toggle .sound-bar:nth-child(1) {
      animation-delay: 0.1s;
      animation-duration: 1.01s; }
    .sound-toggle .sound-bar:nth-child(2) {
      animation-delay: 0.2s;
      animation-duration: 1.01s; }
    .sound-toggle .sound-bar:nth-child(3) {
      animation-delay: 0.3s;
      animation-duration: 1.01s; }
    .sound-toggle .sound-bar:nth-child(4) {
      animation-delay: 0.4s;
      animation-duration: 1.04s; }
    .sound-toggle .sound-bar:nth-child(5) {
      animation-delay: 0.5s;
      animation-duration: 1.04s; }
  .sound-toggle[data-sound="off"] .icon-sound-bar:before, .sound-toggle[data-sound="off"] .icon-sound-bar:after {
    width: 35px;
    opacity: 1;
    transition-duration: .3s; }
    @media all and (max-width: 767px) {
      .sound-toggle[data-sound="off"] .icon-sound-bar:before, .sound-toggle[data-sound="off"] .icon-sound-bar:after {
        width: 8vw; } }
  .sound-toggle[data-sound="off"] .sound-bar {
    animation: none;
    height: 5px;
    transition-duration: .3s; }
    @media all and (max-width: 767px) {
      .sound-toggle[data-sound="off"] .sound-bar {
        height: 1vw; } }

.registration {
  height: 100vh;
  padding-top: 110px; }
  @media (max-width: 1024px) and (orientation: portrait) {
    .registration {
      padding-top: 20vw; } }
  @media all and (max-width: 767px) {
    .registration {
      padding-top: 33vw;
      height: auto;
      overflow: hidden; } }
  [data-reg="true"] .registration {
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;
    animation: fadeOut 700ms linear forwards; }
  @media (min-width: 1025px) {
    [data-device="iPadPro"] .registration .img-block {
      width: 52vw;
      right: -12vw; } }
  .registration .container {
    height: 100%;
    position: relative;
    max-width: 1340px; }
    @media all and (max-width: 1919px) {
      .registration .container {
        max-width: 75vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .registration .container {
        max-width: 100vw; } }
  .registration .content-wrapper {
    max-width: 980px;
    width: 750px;
    margin-left: 0; }
    @media all and (max-width: 1919px) {
      .registration .content-wrapper {
        margin-left: 0;
        width: 40vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .registration .content-wrapper {
        width: 60vw;
        margin: 0 auto; } }
    @media all and (max-width: 767px) {
      .registration .content-wrapper {
        width: 85.5vw; } }
  .registration .logos-wrapper {
    position: relative;
    margin-bottom: 20px; }
    @media all and (max-width: 767px) {
      .registration .logos-wrapper {
        margin-bottom: 9vw; } }
    .registration .logos-wrapper img {
      width: 100%; }
    .registration .logos-wrapper .regular-logo {
      width: 100%; }
    .registration .logos-wrapper .chiens-logo {
      width: 105px;
      position: absolute;
      left: 46%;
      bottom: 210px;
      transform: translate(-50%, 0); }
      @media (max-width: 1920px) {
        .registration .logos-wrapper .chiens-logo {
          width: 6vw;
          bottom: 11vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .registration .logos-wrapper .chiens-logo {
          width: 12vw;
          bottom: 18vw; } }
      @media all and (max-width: 767px) {
        .registration .logos-wrapper .chiens-logo {
          width: 18vw;
          bottom: 26vw; } }
      .registration .logos-wrapper .chiens-logo.lyon {
        width: 163px; }
        @media (max-width: 1920px) {
          .registration .logos-wrapper .chiens-logo.lyon {
            width: 8.5vw; } }
        @media (max-width: 1024px) and (orientation: portrait) {
          .registration .logos-wrapper .chiens-logo.lyon {
            width: 19vw; } }
        @media all and (max-width: 767px) {
          .registration .logos-wrapper .chiens-logo.lyon {
            width: 27vw; } }
  .registration .text-block {
    text-align: center;
    font-size: 20px;
    width: 100%;
    max-width: 625px;
    margin: 0 0 15px; }
    @media all and (max-width: 1919px) {
      .registration .text-block {
        max-width: 32.5vw;
        margin-bottom: 0.8vw;
        font-size: 1.03vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .registration .text-block {
        max-width: 60vw;
        margin: 0 auto;
        font-size: 2.5vw; } }
    @media all and (max-width: 767px) {
      .registration .text-block {
        max-width: 100%;
        font-size: 4.5vw; } }
    .registration .text-block p {
      margin: 0 0 1.25em; }
      @media all and (max-width: 767px) {
        .registration .text-block p {
          margin-bottom: 12vw; } }
    .registration .text-block article {
      text-transform: uppercase;
      font-family: "Plumpfull"; }
  .registration .img-block {
    position: absolute;
    bottom: 0;
    top: 25px;
    width: 665px;
    left: 50%; }
    .registration .img-block img {
      display: block;
      width: 100%; }
      @media (min-width: 1024px) {
        .registration .img-block img {
          width: auto;
          height: 100%; } }
    @media all and (max-width: 1919px) {
      .registration .img-block {
        width: 38.636vw;
        top: 1.3vw;
        right: 1.5vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .registration .img-block {
        width: 95vw;
        top: auto;
        right: 0;
        margin: 0 auto;
        left: 0;
        bottom: -70vw; } }
    @media all and (max-width: 767px) {
      .registration .img-block {
        width: 136vw;
        margin: 0 auto;
        left: -20.7vw;
        top: 100%;
        height: 64vw;
        overflow: hidden;
        position: relative;
        bottom: 0; } }

.form-registration {
  width: 100%;
  max-width: 555px;
  margin: 0 0 0 35px;
  position: relative; }
  [data-reg="true"] .form-registration {
    display: none;
    transition-delay: .5s; }
  @media all and (max-width: 1919px) {
    .form-registration {
      max-width: 28.91vw;
      margin: 0 0 0 1.8vw; } }
  @media (max-width: 1024px) and (orientation: portrait) {
    .form-registration {
      max-width: 50vw;
      margin: 0 auto; } }
  @media all and (max-width: 767px) {
    .form-registration {
      max-width: 100%; } }

@-webkit-keyframes autofill {
  to {
    color: #6F5B4B;
    background-color: transparent; } }
  .form-registration input:not([type="radio"]),
  .form-registration input[type="text"],
  .form-registration input[type="number"],
  .form-registration input[type="email"],
  .form-registration input:-webkit-autofill,
  .form-registration input:-webkit-autofill:hover,
  .form-registration input:-webkit-autofill:focus,
  .form-registration textarea:-webkit-autofill,
  .form-registration textarea:-webkit-autofill:hover,
  .form-registration textarea:-webkit-autofill:focus,
  .form-registration select:-webkit-autofill,
  .form-registration select:-webkit-autofill:hover,
  .form-registration select:-webkit-autofill:focus {
    width: 100%;
    background-image: url("../img/bg-input.png") !important;
    background-size: 100% 100%;
    background-position: left top;
    background-repeat: no-repeat;
    background-color: transparent !important;
    box-sizing: border-box;
    height: 60px;
    border: none;
    outline: none;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 700;
    color: #6F5B4B !important;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both; }
    @media all and (max-width: 1919px) {
      .form-registration input:not([type="radio"]),
      .form-registration input[type="text"],
      .form-registration input[type="number"],
      .form-registration input[type="email"],
      .form-registration input:-webkit-autofill,
      .form-registration input:-webkit-autofill:hover,
      .form-registration input:-webkit-autofill:focus,
      .form-registration textarea:-webkit-autofill,
      .form-registration textarea:-webkit-autofill:hover,
      .form-registration textarea:-webkit-autofill:focus,
      .form-registration select:-webkit-autofill,
      .form-registration select:-webkit-autofill:hover,
      .form-registration select:-webkit-autofill:focus {
        height: 3.125vw;
        padding: 0 1vw;
        font-size: 1vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .form-registration input:not([type="radio"]),
      .form-registration input[type="text"],
      .form-registration input[type="number"],
      .form-registration input[type="email"],
      .form-registration input:-webkit-autofill,
      .form-registration input:-webkit-autofill:hover,
      .form-registration input:-webkit-autofill:focus,
      .form-registration textarea:-webkit-autofill,
      .form-registration textarea:-webkit-autofill:hover,
      .form-registration textarea:-webkit-autofill:focus,
      .form-registration select:-webkit-autofill,
      .form-registration select:-webkit-autofill:hover,
      .form-registration select:-webkit-autofill:focus {
        height: 6vw;
        padding: 0 2vw;
        font-size: 2vw; } }
    @media all and (max-width: 767px) {
      .form-registration input:not([type="radio"]),
      .form-registration input[type="text"],
      .form-registration input[type="number"],
      .form-registration input[type="email"],
      .form-registration input:-webkit-autofill,
      .form-registration input:-webkit-autofill:hover,
      .form-registration input:-webkit-autofill:focus,
      .form-registration textarea:-webkit-autofill,
      .form-registration textarea:-webkit-autofill:hover,
      .form-registration textarea:-webkit-autofill:focus,
      .form-registration select:-webkit-autofill,
      .form-registration select:-webkit-autofill:hover,
      .form-registration select:-webkit-autofill:focus {
        height: 17vw;
        padding: 0 6vw;
        font-size: 6vw; } }
  .form-registration input[type="radio"] {
    display: none; }
    .form-registration input[type="radio"] + label {
      font-size: 24px;
      font-weight: 700; }
      @media all and (max-width: 1919px) {
        .form-registration input[type="radio"] + label {
          font-size: 1.25vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .form-registration input[type="radio"] + label {
          font-size: 2.5vw; } }
      @media all and (max-width: 767px) {
        .form-registration input[type="radio"] + label {
          font-size: 4.75vw; } }
      .form-registration input[type="radio"] + label:before {
        content: "";
        font-family: 'chiens';
        text-align: center;
        font-size: 16px;
        display: block;
        width: 36px;
        height: 34px;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 100% 100%;
        background-image: url("../img/bg-check.png");
        margin-right: 15px;
        line-height: 34px;
        color: transparent;
        transition-duration: .3s; }
        @media all and (max-width: 1919px) {
          .form-registration input[type="radio"] + label:before {
            width: 1.878vw;
            margin-right: 0.8vw;
            height: 1.76vw;
            font-size: 0.83vw;
            line-height: 1.9vw; } }
        @media (max-width: 1024px) and (orientation: portrait) {
          .form-registration input[type="radio"] + label:before {
            width: 3vw;
            margin-right: 1vw;
            height: 3vw;
            font-size: 1vw;
            line-height: 3vw; } }
        @media all and (max-width: 767px) {
          .form-registration input[type="radio"] + label:before {
            width: 6vw;
            height: 6vw;
            line-height: 6vw;
            font-size: 2vw;
            margin-right: 2vw; } }
    .form-registration input[type="radio"]:checked + label:before {
      color: #6F5B4B;
      font-size: 26px;
      transition-duration: .3s; }
      @media all and (max-width: 1919px) {
        .form-registration input[type="radio"]:checked + label:before {
          font-size: 1.35vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .form-registration input[type="radio"]:checked + label:before {
          font-size: 2.5vw; } }
      @media all and (max-width: 767px) {
        .form-registration input[type="radio"]:checked + label:before {
          font-size: 4.5vw; } }
  .form-registration .input-wrapper {
    position: relative; }
    .form-registration .input-wrapper-text {
      background-image: url("../img/bg-input.png") !important;
      background-size: 100% 100%;
      background-position: left top;
      background-repeat: no-repeat; }
  .form-registration .input-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    padding-top: 30px; }
    @media all and (max-width: 1919px) {
      .form-registration .input-block {
        padding-top: 1.6vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .form-registration .input-block {
        padding-top: 5vw; } }
    @media all and (max-width: 767px) {
      .form-registration .input-block {
        padding-top: 9vw; } }
    .form-registration .input-block input:focus + .label, .form-registration .input-block input.is-value + .label {
      transform: translate(0, -100%);
      top: -8px;
      transition-duration: .3s;
      color: white; }
      @media all and (max-width: 1919px) {
        .form-registration .input-block input:focus + .label, .form-registration .input-block input.is-value + .label {
          top: -0.42vw; } }
      @media all and (max-width: 767px) {
        .form-registration .input-block input:focus + .label, .form-registration .input-block input.is-value + .label {
          font-size: 4vw; } }
    .form-registration .input-block .label {
      position: absolute;
      color: #CCBFB6;
      font-weight: 700;
      font-size: 20px;
      top: 50%;
      transform: translate(0, -50%);
      margin-left: 20px;
      pointer-events: none;
      transition-duration: .3s; }
      @media all and (max-width: 1919px) {
        .form-registration .input-block .label {
          font-size: 1vw;
          margin-left: 1vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .form-registration .input-block .label {
          font-size: 2vw;
          margin-left: 2vw; } }
      @media all and (max-width: 767px) {
        .form-registration .input-block .label {
          font-size: 6vw;
          margin-left: 6vw; } }
    .form-registration .input-block.active {
      position: relative;
      pointer-events: all;
      opacity: 1; }
    .form-registration .input-block.is-done {
      z-index: 1;
      transform: scale(1.5);
      filter: blur(25px);
      transition-duration: 1s; }
    @media all and (max-width: 767px) {
      .form-registration .input-block--check {
        margin-top: 7vw; } }
    .form-registration .input-block--check .inner-wrapper {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 12.5px 0;
      box-sizing: border-box; }
      @media all and (max-width: 1919px) {
        .form-registration .input-block--check .inner-wrapper {
          padding: 0.83vw 0; } }
    .form-registration .input-block--check .label {
      transform: none;
      top: 0;
      color: white; }
    .form-registration .input-block--check label {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
    .form-registration .input-block--check .input-wrapper {
      margin-left: 20px; }
      @media all and (max-width: 1919px) {
        .form-registration .input-block--check .input-wrapper {
          margin-left: 1vw; } }
      @media all and (max-width: 767px) {
        .form-registration .input-block--check .input-wrapper {
          margin-left: 6vw; } }
      .form-registration .input-block--check .input-wrapper + .input-wrapper {
        margin-left: 30px; }
        @media all and (max-width: 1919px) {
          .form-registration .input-block--check .input-wrapper + .input-wrapper {
            margin-left: 1.5vw; } }
        @media (max-width: 1024px) and (orientation: portrait) {
          .form-registration .input-block--check .input-wrapper + .input-wrapper {
            margin-left: 3vw; } }
        @media all and (max-width: 767px) {
          .form-registration .input-block--check .input-wrapper + .input-wrapper {
            margin-left: 3vw; } }
    .form-registration .input-block--check .next-step {
      color: white; }
  .form-registration .next-step {
    font-size: 20px;
    color: #CCBFB6;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%); }
    .form-registration .next-step:hover {
      cursor: pointer; }
    @media all and (max-width: 1919px) {
      .form-registration .next-step {
        right: 1.5vw;
        font-size: 1vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .form-registration .next-step {
        font-size: 2vw;
        right: 2vw; } }
    @media all and (max-width: 767px) {
      .form-registration .next-step {
        font-size: 4vw;
        right: 5vw; } }
  .form-registration .error-message-block {
    margin-top: 12px;
    color: #CE0000;
    font-style: italic;
    font-size: 16px;
    margin-left: 20px;
    opacity: 0;
    pointer-events: none;
    transition-duration: .3s; }
    @media all and (max-width: 1919px) {
      .form-registration .error-message-block {
        font-size: 0.83vw;
        margin-left: 1vw;
        margin-top: 0.65vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .form-registration .error-message-block {
        font-size: 2vw;
        margin-left: 2vw;
        margin-top: 1vw; } }
    @media all and (max-width: 767px) {
      .form-registration .error-message-block {
        font-size: 4vw;
        margin-left: 6vw;
        margin-top: 2vw; } }
    .form-registration .error-message-block.is-visible {
      transition-duration: .3s;
      opacity: 1; }

@-moz-document url-prefix() {
  .form-registration input {
    width: 100%;
    background-image: url("../img/bg-input.png") !important;
    background-size: 100% 100%;
    background-position: left top;
    background-repeat: no-repeat;
    background-color: transparent !important;
    box-sizing: border-box;
    height: 60px;
    border: none;
    outline: none;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 700;
    color: #6F5B4B !important;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both; }
    @media all and (max-width: 1919px) {
      .form-registration input {
        height: 3.125vw;
        padding: 0 1vw;
        font-size: 1vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .form-registration input {
        height: 6vw;
        padding: 0 2vw;
        font-size: 2vw; } }
    @media all and (max-width: 767px) {
      .form-registration input {
        height: 17vw;
        padding: 0 6vw;
        font-size: 6vw; } } }

.warning {
  text-align: center;
  height: 100%;
  height: 100vh;
  padding-top: 30px;
  display: none;
  opacity: 0;
  pointer-events: none; }
  @media all and (max-width: 767px) {
    .warning {
      padding-top: 33vw;
      overflow: hidden;
      height: calc(100vh - 24.4vw);
      min-height: 150vw; } }
  [data-reg="true"] .warning {
    display: block;
    pointer-events: all;
    animation: fadeIn 700ms linear forwards;
    animation-delay: 200ms; }
  [data-quiz="true"] .warning {
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;
    animation: fadeOut 700ms linear forwards; }
  .warning .container {
    height: 100%;
    position: relative; }
  .warning .chiens-logo {
    width: 105px;
    position: absolute;
    left: 46%;
    top: -80px;
    display: none;
    transform: translate(-50%, 0); }
    @media (max-width: 1920px) {
      .warning .chiens-logo {
        width: 6vw;
        top: -5vw; } }
    @media all and (max-width: 1919px) {
      .warning .chiens-logo {
        width: 12vw;
        top: -12vw; } }
    @media all and (max-width: 767px) {
      .warning .chiens-logo {
        width: 18vw;
        top: -21vw;
        display: block; } }
  .warning .logos-wrapper {
    width: 400px;
    margin: 0 auto; }
    @media all and (max-width: 1919px) {
      .warning .logos-wrapper {
        width: 21vw; } }
    @media all and (max-width: 767px) {
      .warning .logos-wrapper {
        width: 85.5vw; } }
    .warning .logos-wrapper img {
      width: 100%; }
  .warning .text-block {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translate(0, -50%); }
    @media all and (max-width: 767px) {
      .warning .text-block {
        top: 50%; } }
  .warning .icon {
    font-size: 90px;
    margin-bottom: 25px; }
    @media all and (max-width: 1919px) {
      .warning .icon {
        font-size: 4.7vw;
        margin-bottom: 1.5vw; } }
    @media all and (max-width: 767px) {
      .warning .icon {
        font-size: 17.5vw;
        margin-bottom: 6vw; } }
  .warning span {
    font-size: 55px;
    font-family: "Plumpfull";
    line-height: 1.1;
    text-transform: uppercase; }
    @media all and (max-width: 1919px) {
      .warning span {
        font-size: 2.9vw; } }
    @media all and (max-width: 767px) {
      .warning span {
        font-size: 6vw; } }

.quiz {
  text-align: center;
  height: 100%;
  display: none;
  opacity: 0;
  pointer-events: none; }
  [data-quiz="true"] .quiz {
    display: block;
    pointer-events: all;
    animation: fadeIn 700ms linear forwards;
    animation-delay: 200ms; }
  [data-finish="true"] .quiz {
    display: block;
    position: absolute;
    top: 0;
    pointer-events: none;
    animation: fadeOut 700ms linear forwards; }
  .quiz-wrapper {
    height: 100%; }
  .quiz .container {
    padding: 0;
    height: 100%; }
  .quiz .regular-logo {
    width: 240px;
    position: absolute;
    top: 30px;
    left: 55px;
    z-index: 1; }
    @media all and (max-width: 1919px) {
      .quiz .regular-logo {
        width: 12.5vw;
        left: 3vw;
        top: 1.5vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz .regular-logo {
        width: 25vw; } }
    @media all and (max-width: 767px) {
      .quiz .regular-logo {
        width: 35vw;
        top: 3vw; } }
    .quiz .regular-logo img {
      width: 100%; }
  .quiz-block {
    padding: 160px 35px 0;
    box-sizing: border-box;
    height: 100%;
    opacity: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media all and (max-width: 1919px) {
      .quiz-block {
        padding: 8vw 1.8vw 0; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz-block {
        padding: 25vw 3vw 0; } }
    @media all and (max-width: 767px) {
      .quiz-block {
        padding-top: 54vw;
        min-height: calc(100vh - 24.3vw); } }
    .quiz-block[data-done="true"] {
      display: block;
      position: absolute;
      top: 0;
      z-index: 1;
      pointer-events: none;
      animation: fadeOut 700ms linear forwards; }
    .quiz-block[data-active="false"] {
      display: none; }
    .quiz-block[data-active="true"] {
      display: block;
      pointer-events: all;
      animation: fadeIn 700ms linear forwards;
      animation-delay: 200ms; }
    .quiz-block.is-right .wrong {
      display: none; }
    .quiz-block.is-wrong .right {
      display: none; }
  .quiz-title {
    font-family: "Plumpfull";
    font-size: 80px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 40px; }
    @media all and (max-width: 1919px) {
      .quiz-title {
        font-size: 4.2vw;
        margin-bottom: 2.1vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz-title {
        font-size: 6vw; } }
    @media all and (max-width: 767px) {
      .quiz-title {
        font-size: 13vw;
        margin-bottom: 8vw; } }
  .quiz .input-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .quiz .input-wrapper:hover {
      transform: scale(1.05); }
    .quiz .input-wrapper input {
      display: none; }
    .quiz .input-wrapper label {
      font-size: 36px;
      font-family: "Plumpfull";
      text-transform: uppercase;
      line-height: 1.09; }
      @media all and (max-width: 1919px) {
        .quiz .input-wrapper label {
          font-size: 1.9vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .quiz .input-wrapper label {
          font-size: 3vw; } }
      @media all and (max-width: 767px) {
        .quiz .input-wrapper label {
          font-size: 4.5vw; } }
      .quiz .input-wrapper label:hover {
        cursor: pointer; }
  .quiz .ou-block {
    color: #CCBFB6;
    width: 65px;
    height: 65px;
    line-height: 62px;
    font-size: 40px;
    font-family: "Plumpfull";
    position: relative;
    margin: 0 60px;
    z-index: 0; }
    @media all and (max-width: 1919px) {
      .quiz .ou-block {
        width: 3.4vw;
        height: 3.4vw;
        font-size: 2vw;
        line-height: 3.3vw;
        margin: 0 3vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz .ou-block {
        width: 6vw;
        height: 6vw;
        font-size: 3.5vw;
        line-height: 6vw;
        margin: 0 5vw; } }
    @media all and (max-width: 767px) {
      .quiz .ou-block {
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
        line-height: 10vw;
        margin: 6vw 0; } }
    .quiz .ou-block .ou-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: translate 8s linear infinite;
      z-index: -1; }
      .quiz .ou-block .ou-bg:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../img/ou.svg");
        animation: rotate 10s linear infinite; }
  .quiz-choice {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px; }
    @media all and (max-width: 1919px) {
      .quiz-choice {
        margin-bottom: 2.6vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz-choice {
        margin-bottom: 10vw; } }
    @media all and (max-width: 767px) {
      .quiz-choice {
        justify-content: center;
        align-items: center;
        flex-direction: column; } }
  .quiz .img-block {
    margin: 0 auto;
    width: 90%; }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz .img-block {
        width: 136vw;
        position: relative;
        left: -21vw; } }
    @media all and (max-width: 767px) {
      .quiz .img-block {
        width: 136vw;
        margin: 0 auto;
        left: -20.7vw;
        top: 100%;
        height: 64vw;
        overflow: hidden;
        position: relative;
        bottom: 0; } }
    .quiz .img-block img {
      width: 100%; }
  .quiz .player {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-image: url("../img/bg-player.svg");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 17px 20px;
    width: 560px;
    margin: 0 auto 25px;
    align-items: center;
    position: relative; }
    @media all and (max-width: 1919px) {
      .quiz .player {
        padding: 1vw;
        margin-bottom: 1.3vw;
        width: 29vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz .player {
        padding: 2.1vw 2vw;
        margin-bottom: 3vw;
        width: 50vw; } }
    @media all and (max-width: 767px) {
      .quiz .player {
        width: 84vw;
        padding: 4vw;
        margin-bottom: 8vw; } }

@keyframes arrow {
  0% {
    transform: translate(0, 0) rotate(0deg); }
  15% {
    transform: translate(-10%, 0) rotate(5deg); }
  25% {
    transform: translate(-10%, 0) rotate(3deg); }
  35% {
    transform: translate(0%, 0) rotate(0deg); }
  50% {
    transform: translate(-10%, 0) rotate(3deg); }
  70% {
    transform: translate(-10%, 0) rotate(5deg); }
  80% {
    transform: translate(0, 0) rotate(0deg); }
  85% {
    transform: translate(-10%, 0) rotate(0deg); }
  90% {
    transform: translate(-3%, 0) rotate(0deg); }
  95% {
    transform: translate(-1%, 0) rotate(0deg); }
  96% {
    transform: translate(0%, 0) rotate(0deg); }
  97% {
    transform: translate(-3%, 0) rotate(0deg); }
  98% {
    transform: translate(-1%, 0) rotate(0deg); }
  99% {
    transform: translate(-2%, 0) rotate(0deg); }
  100% {
    transform: translate(0, 0) rotate(0deg); } }

@keyframes arrow-mobile {
  0% {
    transform: translate(0, 0) rotate(0deg); }
  15% {
    transform: translate(0, -10%) rotate(5deg); }
  25% {
    transform: translate(0, -10%) rotate(3deg); }
  35% {
    transform: translate(0, 0) rotate(0deg); }
  50% {
    transform: translate(0, -10%) rotate(3deg); }
  70% {
    transform: translate(0, -10%) rotate(5deg); }
  80% {
    transform: translate(0, 0) rotate(0deg); }
  85% {
    transform: translate(0, -10%) rotate(0deg); }
  90% {
    transform: translate(0, -3%) rotate(0deg); }
  95% {
    transform: translate(0, -1%) rotate(0deg); }
  96% {
    transform: translate(0, 0) rotate(0deg); }
  97% {
    transform: translate(0, -3%) rotate(0deg); }
  98% {
    transform: translate(0, -1%) rotate(0deg); }
  99% {
    transform: translate(0, -2%) rotate(0deg); }
  100% {
    transform: translate(0, 0) rotate(0deg); } }
    .quiz .player.is-active .arrow-block:before {
      animation: arrow 3s linear; }
      @media all and (max-width: 767px) {
        .quiz .player.is-active .arrow-block:before {
          animation: arrow-mobile 3s linear; } }
    .quiz .player .arrow-block {
      position: absolute;
      font-size: 30px;
      text-transform: uppercase;
      font-family: "Plumpfull";
      left: -180px;
      top: -75px;
      pointer-events: none; }
      @media all and (max-width: 1919px) {
        .quiz .player .arrow-block {
          font-size: 1.5vw;
          left: -9.5vw;
          top: -3.7vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .quiz .player .arrow-block {
          font-size: 2vw;
          left: -12.5vw;
          top: -3.7vw; } }
      @media all and (max-width: 767px) {
        .quiz .player .arrow-block {
          font-size: 2.5vw;
          left: 12vw;
          top: -6.7vw; } }
      .quiz .player .arrow-block:before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        right: 15px;
        width: 80px;
        height: 80px;
        background-image: url("../img/arrow.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        animation: arrow 3s linear infinite; }
        @media all and (max-width: 1919px) {
          .quiz .player .arrow-block:before {
            width: 4.17vw;
            height: 4.17vw;
            right: 0.8vw; } }
        @media (max-width: 1024px) and (orientation: portrait) {
          .quiz .player .arrow-block:before {
            width: 6vw;
            height: 6vw; } }
        @media all and (max-width: 767px) {
          .quiz .player .arrow-block:before {
            background-image: url("../img/arrow-mobile.svg");
            width: 8vw;
            height: 6.5vw;
            left: -10vw;
            top: 0;
            right: auto;
            animation: arrow-mobile 3s linear infinite; } }
    .quiz .player .timeline {
      margin: 0 10px;
      width: 50%;
      height: 12px;
      flex-grow: 1;
      position: relative; }
      @media all and (max-width: 1919px) {
        .quiz .player .timeline {
          height: 0.625vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .quiz .player .timeline {
          height: 1.2vw;
          margin: 0 1vw; } }
      @media all and (max-width: 767px) {
        .quiz .player .timeline {
          height: 3vw;
          margin: 0 3vw; } }
      .quiz .player .timeline svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      @media all and (max-width: 1919px) {
        .quiz .player .timeline {
          margin: 0 0.5vw; } }
    .quiz .player .play:hover, .quiz .player .replay:hover {
      cursor: pointer; }
    .quiz .player .play {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../img/play.png");
      transition-duration: .3s;
      width: 25px;
      height: 27px; }
      @media all and (max-width: 1919px) {
        .quiz .player .play {
          width: 1.3vw;
          height: 1.4vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .quiz .player .play {
          width: 2.3vw;
          height: 2.4vw; } }
      @media all and (max-width: 767px) {
        .quiz .player .play {
          width: 4vw;
          height: 4vw; } }
      .quiz .player .play[data-play="true"] {
        background-image: url("../img/pause.png");
        transition-duration: .3s; }
    .quiz .player .replay {
      width: 30px;
      height: 32px; }
      @media all and (max-width: 1919px) {
        .quiz .player .replay {
          width: 1.6vw;
          height: 1.67vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .quiz .player .replay {
          width: 2.6vw;
          height: 2.67vw; } }
      @media all and (max-width: 767px) {
        .quiz .player .replay {
          width: 4vw;
          height: 4vw; } }
      .quiz .player .replay img {
        display: block;
        width: 100%; }
    .quiz .player .time-line {
      transition-duration: 0.1s; }
  [data-answer="true"] .quiz-block--question {
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;
    animation: fadeOut 700ms linear forwards; }
    @media all and (max-width: 767px) {
      [data-answer="true"] .quiz-block--question {
        left: 0;
        width: 100%;
        top: 48vw; } }
  .quiz-block--img-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 55%;
    display: none;
    pointer-events: none;
    opacity: 0; }
    .quiz-block--img-block img {
      width: 100%;
      display: block; }
    [data-answer="true"] .quiz-block--img-block {
      display: block;
      pointer-events: all;
      animation: fadeIn 700ms linear forwards;
      animation-delay: 200ms; }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz-block--img-block {
        width: 100%; } }
    @media all and (max-width: 767px) {
      .quiz-block--img-block {
        width: 160%;
        left: -30%; } }
  .quiz-block--result {
    margin: 0 auto;
    width: 100%;
    display: none;
    pointer-events: none;
    opacity: 0;
    padding-top: 305px; }
    @media all and (max-width: 1919px) {
      .quiz-block--result {
        padding-top: 16vw; } }
    @media all and (max-width: 767px) {
      .quiz-block--result {
        padding-top: 28vw; } }
    [data-answer="true"] .quiz-block--result {
      display: block;
      pointer-events: all;
      animation: fadeIn 700ms linear forwards;
      animation-delay: 200ms;
      z-index: 1;
      position: relative; }
    .quiz-block--result .text-block {
      text-align: center;
      position: relative;
      width: 100%;
      max-width: 935px;
      margin: 0 auto;
      z-index: 1;
      font-size: 24px;
      text-shadow: 0px 0px 3px black; }
      @media all and (max-width: 1919px) {
        .quiz-block--result .text-block {
          font-size: 1.25vw;
          max-width: 49vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .quiz-block--result .text-block {
          font-size: 2vw;
          max-width: 75vw; } }
      @media all and (max-width: 767px) {
        .quiz-block--result .text-block {
          font-size: 4vw;
          max-width: 87vw;
          margin-bottom: 10vw; } }
    .quiz-block--result .next-quiz {
      font-size: 26px;
      font-family: "Plumpfull";
      text-transform: uppercase;
      margin-top: 30px;
      background: none;
      outline: none;
      border: none;
      color: white; }
      @media all and (max-width: 1919px) {
        .quiz-block--result .next-quiz {
          font-size: 1.35vw;
          margin-top: 1.5vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .quiz-block--result .next-quiz {
          font-size: 2.5vw;
          margin-top: 3vw; } }
      @media all and (max-width: 767px) {
        .quiz-block--result .next-quiz {
          font-size: 5.3vw;
          margin-top: 8vw; } }
      .quiz-block--result .next-quiz .icon {
        font-size: 80%;
        margin-left: 1em; }
      .quiz-block--result .next-quiz:hover {
        cursor: pointer; }
    .quiz-block--result .quiz-title {
      margin-bottom: 20px; }
      @media all and (max-width: 1919px) {
        .quiz-block--result .quiz-title {
          margin-bottom: 1.1vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .quiz-block--result .quiz-title {
          margin-bottom: 2vw; } }

.mask {
  position: absolute;
  left: -999em;
  pointer-events: none;
  opacity: 0; }

.quiz-step-line {
  position: absolute;
  right: 60px;
  top: 40%;
  font-family: "Plumpfull";
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  transform: translate(0, -50%);
  height: 400px;
  overflow: hidden;
  mask: url(../img/mask2.svg) top left/cover; }
  @media all and (max-width: 1919px) {
    .quiz-step-line {
      height: 21vw;
      right: 3vw;
      font-size: 0.9vw; } }
  @media (max-width: 1024px) and (orientation: portrait) {
    .quiz-step-line {
      height: 40vw;
      right: 4vw;
      font-size: 2vw; } }
  @media all and (max-width: 767px) {
    .quiz-step-line {
      left: 0;
      right: 0;
      top: 32vw;
      mask: url(../img/mask3.svg) top left/cover;
      font-size: 3.5vw;
      height: 12vw;
      width: 87vw;
      overflow: hidden;
      margin: 0 auto;
      transform: none; } }
  .quiz-step-line[data-step="1"] li {
    transform: translate(0, 0%);
    transition-duration: .7s; }
    @media all and (max-width: 767px) {
      .quiz-step-line[data-step="1"] li {
        transform: translate(0%, 0); } }
  .quiz-step-line[data-step="2"] li {
    transform: translate(0, -100%);
    transition-duration: .7s; }
    @media all and (max-width: 767px) {
      .quiz-step-line[data-step="2"] li {
        transform: translate(-100%, 0); } }
  .quiz-step-line[data-step="3"] li {
    transform: translate(0, -200%);
    transition-duration: .7s; }
    @media all and (max-width: 767px) {
      .quiz-step-line[data-step="3"] li {
        transform: translate(-200%, 0); } }
  .quiz-step-line[data-step="4"] li {
    transform: translate(0, -300%);
    transition-duration: .7s; }
    @media all and (max-width: 767px) {
      .quiz-step-line[data-step="4"] li {
        transform: translate(-300%, 0); } }
  .quiz-step-line:before {
    content: '';
    width: 100%;
    height: 50%;
    display: block; }
    @media all and (max-width: 767px) {
      .quiz-step-line:before {
        display: none; } }
  .quiz-step-line .numb-block {
    font-size: 26px;
    margin-bottom: 5px; }
    @media all and (max-width: 1919px) {
      .quiz-step-line .numb-block {
        margin-bottom: 0.3vw;
        font-size: 1.35vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz-step-line .numb-block {
        font-size: 3vw;
        margin-bottom: 1vw; } }
    @media all and (max-width: 767px) {
      .quiz-step-line .numb-block {
        font-size: 5.6vw;
        margin-bottom: 1.5vw; } }
    .quiz-step-line .numb-block sup {
      top: -0.5em;
      font-size: 55%; }
  .quiz-step-line ul {
    margin-top: -25px;
    pointer-events: none; }
    @media all and (max-width: 1919px) {
      .quiz-step-line ul {
        margin-top: -1.3vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz-step-line ul {
        margin-top: -2.5vw; } }
    @media all and (max-width: 767px) {
      .quiz-step-line ul {
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: absolute;
        top: 0;
        left: 50%;
        width: 226vw;
        margin-left: -5vw; } }
  @media all and (max-width: 767px) {
    .quiz-step-line li {
      padding-right: 42vw;
      position: relative; } }
  .quiz-step-line li:after {
    content: '';
    width: 11px;
    height: 200px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../img/dots.svg");
    display: block;
    margin: 10px auto; }
    @media all and (max-width: 1919px) {
      .quiz-step-line li:after {
        width: 0.574vw;
        height: 10.4vw;
        margin: 0.52vw auto; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .quiz-step-line li:after {
        width: 1vw;
        height: 20vw;
        margin: 1vw auto; } }
    @media all and (max-width: 767px) {
      .quiz-step-line li:after {
        background-image: url("../img/dots2.svg");
        height: 2vw;
        width: 40vw;
        position: absolute;
        right: 1vw;
        top: 50%;
        margin-top: -1vw; } }
  .quiz-step-line li:last-child:after {
    background: none; }

.merci {
  height: 100%;
  padding-top: 110px;
  opacity: 0;
  display: none; }
  @media (max-width: 1600px) {
    .merci {
      padding-top: 5.5vw; } }
  @media (max-width: 1366px) {
    .merci {
      padding-top: 8vw; } }
  @media all and (max-width: 767px) {
    .merci {
      padding-top: 24vw; } }
  [data-finish="true"] .merci {
    display: block;
    pointer-events: all;
    animation: fadeIn 700ms linear forwards;
    animation-delay: 200ms; }
  @media all and (max-width: 767px) {
    .merci .regular-logo {
      position: absolute;
      z-index: 1;
      width: 35vw;
      top: 3vw;
      left: 3vw; } }
  .merci .regular-logo img {
    width: 100%; }
  .merci .container {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    max-width: 1440px; }
    @media (max-width: 1919px) {
      .merci .container {
        max-width: 75vw; } }
    @media all and (max-width: 767px) {
      .merci .container {
        max-width: 100%;
        flex-direction: column-reverse; } }
  .merci .content-block {
    text-align: center;
    width: 100%;
    max-width: 680px; }
    @media (max-width: 1920px) {
      .merci .content-block {
        max-width: 35.45vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .merci .content-block {
        max-width: 80vw; } }
    @media all and (max-width: 767px) {
      .merci .content-block {
        max-width: 100%;
        margin-bottom: 12vw; } }
  .merci .img-block {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 700px; }
    @media (max-width: 1920px) {
      .merci .img-block {
        width: 36.5vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .merci .img-block {
        margin-top: -45vw;
        margin-left: -4vw;
        width: 80vw;
        bottom: 0;
        top: 100%; } }
    @media all and (max-width: 767px) {
      .merci .img-block {
        width: 136vw;
        margin: 0 auto;
        left: -20.7vw;
        top: 100%;
        height: 64vw;
        overflow: hidden;
        position: relative;
        bottom: 0; } }
    .merci .img-block img {
      width: 100%;
      display: block; }
  .merci .result-block {
    text-transform: uppercase;
    font-size: 28px;
    color: #CCBFB6;
    font-weight: bold;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../img/bg-count.png");
    text-align: center;
    box-sizing: border-box;
    display: inline-flex;
    width: 400px;
    height: 75px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; }
    @media (max-width: 1920px) {
      .merci .result-block {
        width: 20.834vw;
        height: 3.91vw;
        font-size: 1.45vw;
        margin-bottom: 0.5vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .merci .result-block {
        width: 45vw;
        height: 7vw;
        font-size: 3vw;
        margin-bottom: 2vw; } }
    @media all and (max-width: 767px) {
      .merci .result-block {
        width: 75vw;
        height: 10.67vw;
        font-size: 5.7vw;
        margin-bottom: 3vw; } }
  .merci--title {
    font-family: "Plumpfull";
    text-transform: uppercase;
    font-size: 36px;
    line-height: 0.9;
    margin-bottom: 15px;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35); }
    @media (max-width: 1919px) {
      .merci--title {
        font-size: 1.3vw;
        margin-bottom: 0.8vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .merci--title {
        font-size: 3vw;
        margin-bottom: 4vw; } }
    @media all and (max-width: 767px) {
      .merci--title {
        font-size: 4.6vw;
        margin-bottom: 13.3vw; } }
    .merci--title .big {
      font-size: 525%; }
  .merci--text {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 25px; }
    @media (max-width: 1919px) {
      .merci--text {
        font-size: 1vw;
        margin-bottom: 1vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .merci--text {
        font-size: 2vw;
        margin-bottom: 2vw; } }
    @media all and (max-width: 767px) {
      .merci--text {
        font-size: 4.3vw;
        margin-bottom: 10vw; } }
    .merci--text p {
      margin: 0;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35); }
  .merci .social-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 15px 0; }
    @media (max-width: 1919px) {
      .merci .social-list {
        margin: 0.8vw 0; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .merci .social-list {
        margin: 2vw 0; } }
    @media all and (max-width: 767px) {
      .merci .social-list {
        margin: 5vw 0; } }
    .merci .social-list li {
      margin-right: 15px; }
      @media (max-width: 1920px) {
        .merci .social-list li {
          margin-right: 0.8vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .merci .social-list li {
          margin-right: 3.5vw; } }
      .merci .social-list li:last-child {
        margin-right: 0; }
      .merci .social-list li:nth-child(1) a {
        transform: rotate(30deg); }
        .merci .social-list li:nth-child(1) a .icon {
          transform: rotate(-30deg); }
      .merci .social-list li:nth-child(2) a {
        transform: rotate(60deg); }
        .merci .social-list li:nth-child(2) a .icon {
          transform: rotate(-60deg); }
      .merci .social-list li:nth-child(3) a {
        transform: rotate(90deg); }
        .merci .social-list li:nth-child(3) a .icon {
          transform: rotate(-90deg); }
    .merci .social-list a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 60px;
      height: 60px;
      z-index: 0;
      font-size: 34px;
      color: #CAB2A2; }
      @media (max-width: 1920px) {
        .merci .social-list a {
          width: 3.13vw;
          height: 3.13vw;
          font-size: 1.8vw; } }
      @media (max-width: 1024px) and (orientation: portrait) {
        .merci .social-list a {
          width: 6vw;
          height: 6vw;
          font-size: 3vw; } }
      @media all and (max-width: 767px) {
        .merci .social-list a {
          width: 10vw;
          height: 10vw;
          font-size: 5vw; } }
    .merci .social-list .bg-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: translate 8s linear infinite;
      z-index: -1; }
      .merci .social-list .bg-block:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../img/ou.svg");
        animation: rotate 10s linear infinite; }
  .merci--don a {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 28px;
    font-family: "Plumpfull";
    width: 320px;
    height: 60px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../img/bg-button.png");
    text-transform: uppercase; }
    @media (max-width: 1920px) {
      .merci--don a {
        font-size: 1.45vw;
        width: 16.67vw;
        height: 3.13vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .merci--don a {
        font-size: 3vw;
        width: 37vw;
        height: 7vw; } }
    @media all and (max-width: 767px) {
      .merci--don a {
        font-size: 5.4vw;
        width: 64vw;
        height: 12vw; } }

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0; }
  @media all and (max-width: 1919px) {
    .footer {
      padding: 2vw 0; } }
  @media (max-width: 1024px) and (orientation: portrait) {
    .footer {
      background: #CCBFB6; } }
  @media all and (max-width: 767px) {
    .footer {
      padding: 2.7vw 0 7vw;
      position: relative; } }
  @media (max-width: 1024px) and (orientation: portrait) {
    .footer .container {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start; } }
  @media all and (max-width: 767px) {
    .footer .container {
      padding: 0 2.7vw; } }
  .footer--credits {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700; }
    [data-reg="true"] .footer--credits {
      pointer-events: none;
      animation: fadeOut 700ms linear forwards; }
      @media all and (max-width: 767px) {
        [data-reg="true"] .footer--credits {
          width: 0;
          padding: 0; } }
    @media all and (max-width: 1919px) {
      .footer--credits {
        font-size: 0.73vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .footer--credits {
        font-size: 2vw;
        width: 50vw;
        flex-grow: 1;
        box-sizing: border-box;
        padding-right: 5vw; } }
    @media all and (max-width: 767px) {
      .footer--credits {
        font-size: 2.7vw; } }
  .footer--chiens-logo {
    position: absolute;
    right: 40px;
    bottom: 110px;
    width: 115px;
    display: none;
    opacity: 0;
    pointer-events: none; }
    @media all and (max-width: 1919px) {
      .footer--chiens-logo {
        right: 2vw;
        bottom: 6vw;
        width: 6vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .footer--chiens-logo {
        position: relative;
        right: 0;
        bottom: 0;
        width: 7vw; } }
    @media all and (max-width: 767px) {
      .footer--chiens-logo {
        width: 12.5vw; } }
    [data-reg="true"] .footer--chiens-logo {
      display: block;
      pointer-events: all;
      animation: fadeIn 700ms linear forwards;
      animation-delay: 200ms; }
      @media all and (max-width: 767px) {
        [data-reg="true"] .footer--chiens-logo .container {
          padding: 0 5vw; } }
    .footer--chiens-logo img {
      display: block;
      width: 100%; }
  [data-reg="true"] .footer .ml-link {
    bottom: 25px;
    transition-duration: .7s; }
    @media (max-width: 1024px) and (orientation: portrait) {
      [data-reg="true"] .footer .ml-link {
        bottom: 0; } }
    @media all and (max-width: 767px) {
      [data-reg="true"] .footer .ml-link span {
        display: block; }
        [data-reg="true"] .footer .ml-link span + span {
          display: none; } }
  [data-reg="true"] .footer .made {
    bottom: 36px; }
    @media (max-width: 1920px) {
      [data-reg="true"] .footer .made {
        bottom: 31px; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      [data-reg="true"] .footer .made {
        right: 3vw;
        bottom: 1vw;
        font-size: 1.3vw; } }
  @media (max-width: 1024px) and (orientation: portrait) {
    [data-reg="true"] .footer .social-list {
      display: block;
      pointer-events: all;
      animation: fadeIn 700ms linear forwards;
      animation-delay: 200ms; } }
  @media all and (max-width: 767px) {
    [data-reg="true"] .footer .right-block {
      margin-top: 2vw; } }
  @media (max-width: 1024px) and (orientation: portrait) {
    .footer .right-block:after {
      content: '';
      display: table;
      width: 100%;
      height: 0;
      clear: both;
      opacity: 0; } }
  .footer .social-list {
    opacity: 0;
    pointer-events: none;
    float: right;
    margin-bottom: 3vw;
    display: none; }
    @media (min-width: 768px) {
      .footer .social-list {
        margin-bottom: 1.5vw; } }
    .footer .social-list li {
      float: left;
      margin-right: 2vw; }
      .footer .social-list li:last-child {
        margin-right: 0; }
    .footer .social-list a {
      width: 6.67vw;
      height: 6.67vw;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      border-radius: 100%;
      font-size: 4vw; }
      @media (min-width: 768px) {
        .footer .social-list a {
          width: 4vw;
          height: 4vw;
          font-size: 2vw; } }
  .footer .ml-link {
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    line-height: 1;
    font-weight: 700;
    display: block;
    padding-bottom: 15px;
    position: absolute;
    right: 70px;
    bottom: 100px; }
    @media all and (max-width: 767px) {
      .footer .ml-link span {
        display: none; } }
    .footer .ml-link span + span {
      display: none; }
      @media all and (max-width: 767px) {
        .footer .ml-link span + span {
          display: block; } }
    @media all and (max-width: 1919px) {
      .footer .ml-link {
        font-size: 0.83vw;
        padding-bottom: 0.75vw;
        bottom: 5.3vw;
        right: 3.5vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .footer .ml-link {
        font-size: 2vw;
        bottom: 0;
        right: 0;
        position: relative;
        clear: both; } }
    @media all and (max-width: 767px) {
      .footer .ml-link {
        font-size: 3vw;
        padding-bottom: 2vw; } }
    .footer .ml-link:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background: white; }
      @media (min-width: 1025px) {
        .footer .ml-link:before {
          transition-duration: .5s; } }
      @media all and (max-width: 767px) {
        .footer .ml-link:before {
          height: 0.75vw; } }
    .footer .ml-link:hover {
      color: #CCBFB6; }
      .footer .ml-link:hover:before {
        background: #CCBFB6; }
        @media (min-width: 1025px) {
          .footer .ml-link:hover:before {
            transition-duration: .5s; } }
  .footer .made {
    position: absolute;
    right: 250px;
    bottom: 112px;
    font-size: 14px; }
    @media (max-width: 1920px) {
      .footer .made {
        right: 13vw;
        bottom: 5.8vw;
        font-size: 0.73vw; } }
    @media (max-width: 1024px) and (orientation: portrait) {
      .footer .made {
        right: 3vw;
        bottom: 1vw;
        font-size: 1.3vw; } }
    @media all and (max-width: 767px) {
      .footer .made {
        right: 3vw;
        bottom: 2vw;
        font-size: 2vw; } }

.popup {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  padding: 40px 50px 60px;
  background: #E6DBD3;
  display: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  -webkit-overflow-scrolling: touch; }
  @media all and (max-width: 767px) {
    .popup {
      padding: 13vw 5vw 8vw; } }
  .is-open-popup .popup {
    filter: blur(0); }
  .popup .close {
    width: 25px;
    height: 35px;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 100%;
    color: white;
    text-align: center;
    line-height: 35px;
    font-size: 18px;
    font-family: "Plumpfull";
    z-index: 1;
    background-image: url("../img/bg-close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-transform: uppercase; }
    @media all and (max-width: 767px) {
      .popup .close {
        top: 3vw;
        right: 3vw;
        width: 8vw;
        height: 8vw;
        line-height: 8.3vw;
        font-size: 3.3vw; } }
    .popup .close:hover {
      cursor: pointer; }
  .popup--wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 100;
    background: rgba(255, 255, 255, 0.7);
    display: none; }
    .popup--wrapper-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      overflow: auto; }
      @media all and (max-width: 767px) {
        .popup--wrapper-inner {
          padding: 0; } }
  .popup--content {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    color: #6F5B4B; }
    .popup--content > *:last-child {
      margin-bottom: 0; }
    .popup--content > *:first-child {
      margin-top: 0; }
  .popup--title {
    font-size: 63px;
    margin-bottom: 30px;
    line-height: 1.2;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: "Plumpfull"; }
    @media all and (max-width: 1024px) {
      .popup--title {
        font-size: 50px; } }
    @media all and (max-width: 767px) {
      .popup--title {
        font-size: 8vw; } }
  .popup h3 {
    font-size: 28px;
    text-transform: uppercase;
    margin: 0 0 0.7em;
    margin-top: 2em; }
    @media all and (max-width: 1024px) {
      .popup h3 {
        font-size: 24px; } }
    @media all and (max-width: 767px) {
      .popup h3 {
        font-size: 5vw; } }
  .popup a {
    text-decoration: underline;
    color: #6F5B4B; }
  .popup p {
    margin: 0 0 1em;
    font-size: 18px;
    line-height: 1.15; }
    @media all and (max-width: 1024px) {
      .popup p {
        font-size: 16px; } }
    @media all and (max-width: 767px) {
      .popup p {
        font-size: 4vw; } }

@keyframes cookieOpen {
  0% {
    transform: translate(0, -100%); }
  100% {
    transform: translate(0, 0); } }

@keyframes cookieClose {
  0% {
    transform: translate(0, 0);
    opacity: 1; }
  100% {
    transform: translate(0, -100%);
    opacity: 0; } }

.cookie-block {
  position: fixed;
  top: 0;
  right: 0;
  box-sizing: border-box;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(0, -100%);
  animation: cookieOpen 1s linear forwards;
  animation-delay: 1s;
  z-index: 99; }
  .cookie-block.is-hidden {
    animation: cookieClose 1s linear forwards;
    animation-delay: 0s; }
  .cookie-block .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 35px; }
    @media all and (max-width: 1024px) {
      .cookie-block .container {
        padding: 15px; } }
    @media (max-width: 767px) {
      .cookie-block .container {
        display: block; } }
  .cookie-block .text-block {
    width: 50%;
    flex-grow: 1;
    font-size: 20px;
    line-height: 1;
    box-sizing: border-box;
    padding-right: 20px;
    font-style: italic; }
    @media (max-width: 1920px) {
      .cookie-block .text-block {
        font-size: 18px; } }
    @media (max-width: 1600px) {
      .cookie-block .text-block {
        font-size: 16px; } }
    .cookie-block .text-block a {
      display: block;
      color: white;
      text-decoration: underline; }
    @media (max-width: 1023px) {
      .cookie-block .text-block {
        font-size: 14px; } }
    @media (max-width: 767px) {
      .cookie-block .text-block {
        font-size: 4vw;
        width: 100%;
        padding: 0;
        margin-bottom: 4vw;
        text-align: justify; } }
  .cookie-block .link-block {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .cookie-block .link-block {
        text-align: center; } }
    .cookie-block .link-block a {
      font-size: 16px;
      font-weight: 800;
      text-decoration: none;
      width: 170px;
      height: 30px;
      background: white;
      border: 1px solid white;
      color: black;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      line-height: 1; }
      .cookie-block .link-block a:hover {
        background: rgba(0, 0, 0, 0.5);
        color: white; }
      @media all and (max-width: 1024px) {
        .cookie-block .link-block a {
          width: 125px;
          font-size: 14px; } }
      .cookie-block .link-block a + a {
        margin-left: 10px; }
        @media all and (max-width: 767px) {
          .cookie-block .link-block a + a {
            margin-left: 4vw; } }
      @media all and (max-width: 767px) {
        .cookie-block .link-block a {
          width: 40%;
          flex-grow: 1;
          height: 10vw;
          font-size: 4vw;
          display: inline-flex; } }
