@import "normalize.css";
@import "partials/mixins";
@import "partials/font-chiens";
@import "partials/app";
@import "partials/header";
@import "partials/registration";
@import "partials/warning";
@import "partials/quiz";
@import "partials/merci";
@import "partials/footer";
@import "partials/popup";
@import "partials/cookie";