.footer{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;

  @media #{$max-width-desktop} {
    padding: 2vw 0;
  }
  @media (max-width: 1024px) and (orientation: portrait){
    background: $color-brown;
  }
  @media #{$max-width-mobile} {
    padding: 2.7vw 0 7vw;
    position: relative;
  }
  .container{
    @media (max-width: 1024px) and (orientation: portrait){
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    @media #{$max-width-mobile} {
      padding: 0 2.7vw;
    }
  }
  &--credits{
    font-size: 14px;
    font-family: $font-family--OpenSans;
    font-weight: 700;
    [data-reg="true"] &{
      pointer-events: none;
      animation: fadeOut 700ms linear forwards;
      @media #{$max-width-mobile}{
        width: 0;
        padding: 0;
      }
    }
    @media #{$max-width-desktop} {
      font-size: 0.73vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      font-size: 2vw;
      width: 50vw;
      flex-grow: 1;
      box-sizing: border-box;
      padding-right: 5vw;
    }
    @media #{$max-width-mobile}{
      font-size: 2.7vw;
    }
  }
  &--chiens-logo{
    position: absolute;
    right: 40px;
    bottom: 110px;
    width: 115px;
    display: none;
    opacity: 0;
    pointer-events: none;
    @media #{$max-width-desktop}{
      right: 2vw;
      bottom: 6vw;
      width: 6vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      position: relative;
      right: 0;
      bottom: 0;
      width: 7vw;
    }
    @media #{$max-width-mobile} {
      width: 12.5vw;
    }
    [data-reg="true"] &{
      display: block;
      pointer-events: all;
      animation: fadeIn 700ms linear forwards;
      animation-delay: 200ms;
      .container{
        @media #{$max-width-mobile}{
          padding: 0 5vw;
        }
      }
    }
    img{
      display: block;
      width: 100%;
    }
  }
  [data-reg="true"] &{
    .ml-link{
      bottom: 25px;
      transition-duration: .7s;
      @media (max-width: 1024px) and (orientation: portrait){
        bottom: 0;
      }
      @media #{$max-width-mobile}{
        span{
          display: block;
          & + span{
            display: none;
          }
        }
      }
    }
    .made{
      bottom: 36px;
      @media (max-width: 1920px){
        bottom: 31px;
      }
      @media (max-width: 1024px) and (orientation: portrait) {
        right: 3vw;
        bottom: 1vw;
        font-size: 1.3vw;
      }
    }
    @media (max-width: 1024px) and (orientation: portrait) {
      .social-list{
        display: block;
        pointer-events: all;
        animation: fadeIn 700ms linear forwards;
        animation-delay: 200ms;
      }
    }
    @media #{$max-width-mobile}{
      .right-block{
        margin-top: 2vw;
      }
    }
  }
  .right-block{
    @media (max-width: 1024px) and (orientation: portrait){
      @include clearfix;
    }
  }
  .social-list{
    opacity: 0;
    pointer-events: none;
    float: right;
    margin-bottom: 3vw;
    display: none;
    @media (min-width: 768px){
      margin-bottom: 1.5vw;
    }
    li{
      float: left;
      margin-right: 2vw;
      &:last-child{
        margin-right: 0;
      }
    }
    a{
      width: 6.67vw;
      height: 6.67vw;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      border-radius: 100%;
      font-size: 4vw;
      @media (min-width: 768px) {
        width: 4vw;
        height: 4vw;
        font-size: 2vw;
      }
    }
  }
  .ml-link{
    font-size: 16px;
    text-transform: uppercase;
    font-family: $font-family--OpenSans;
    line-height: 1;
    font-weight: 700;
    display: block;
    padding-bottom: 15px;
    position: absolute;
    right: 70px;
    bottom: 100px;
    @extend %link-hover-transition;
    span{
      @media #{$max-width-mobile}{
        display: none;
      }
     & + span{
        display: none;
        @media #{$max-width-mobile}{
          display: block;
        }
      }
    }
    @media #{$max-width-desktop} {
      font-size: 0.83vw;
      padding-bottom: 0.75vw;
      bottom: 5.3vw;
      right: 3.5vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      font-size: 2vw;
      bottom: 0;
      right: 0;
      position: relative;
      clear: both;
    }
    @media #{$max-width-mobile}{
      font-size: 3vw;
      padding-bottom: 2vw;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background: white;
      @media (min-width: 1025px) {
        transition-duration: .5s;
      }
      @media #{$max-width-mobile}{
        height: 0.75vw;
      }
    }
    &:hover{
      color: $color-brown;
      &:before{
        background: $color-brown;
        @media (min-width: 1025px) {
          transition-duration: .5s;
        }
      }
    }
  }
  .made{
    position: absolute;
    right: 250px;
    bottom: 112px;
    font-size: 14px;
    @media (max-width: 1920px) {
      right: 13vw;
      bottom: 5.8vw;
      font-size: 0.73vw;
    }
    @media (max-width: 1024px) and (orientation: portrait) {
      right: 3vw;
      bottom: 1vw;
      font-size: 1.3vw;
    }
    @media #{$max-width-mobile} {
      right: 3vw;
      bottom: 2vw;
      font-size: 2vw;
    }
  }
}