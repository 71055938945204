.registration{
  height: 100vh;
  padding-top: 110px;
  @media (max-width: 1024px) and (orientation: portrait){
    padding-top: 20vw;
  }
  @media #{$max-width-mobile} {
    padding-top: 33vw;
    height: auto;
    overflow: hidden;
  }
  [data-reg="true"] &{
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;
    animation: fadeOut 700ms linear forwards;
  }
  [data-device="iPadPro"] &{
    @media (min-width: 1025px) {
      .img-block{
        width: 52vw;
        right: -12vw;
      }
    }
  }
  //[data-device="iPad"] &{
  //  height: auto;
  //  .img-block{
  //    top: 100%;
  //    bottom: 0;
  //    height: 450px;
  //    overflow: hidden;
  //  }
  //}
  .container{
    height: 100%;
    position: relative;
    max-width: 1340px;
    @media #{$max-width-desktop}{
      max-width: 75vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      max-width: 100vw;
    }
  }
  .content-wrapper{
    max-width: 980px;
    //width: 40vw;
    width: 750px;
    //margin-left: 235px;
    margin-left: 0;
    @media #{$max-width-desktop} {
      //margin-left: 13.45vw;
      margin-left: 0;
      width: 40vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      width: 60vw;
      margin: 0 auto;
    }
    @media #{$max-width-mobile} {
      width: 85.5vw;
    }
  }
  .logos-wrapper{
    position: relative;
    margin-bottom: 20px;
    @media #{$max-width-mobile} {
      margin-bottom: 9vw;
    }
    img{
      width: 100%;
    }
    .regular-logo{
      width: 100%;
    }
    .chiens-logo{
      width: 105px;
      position: absolute;
      left: 46%;
      //top: -80px;
      bottom: 210px;
      transform: translate(-50%,0);
      @media (max-width: 1920px) {
        width: 6vw;
        //top: -5vw;
        bottom: 11vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        width: 12vw;
        //top: -12vw;
        bottom: 18vw;
      }
      @media #{$max-width-mobile}{
        width: 18vw;
        //top: -21vw;
        bottom: 26vw;
      }
      &.lyon{
        width: 163px;
        @media (max-width: 1920px){
          width: 8.5vw;
        }
        @media (max-width: 1024px) and (orientation: portrait){
          width: 19vw;
        }
        @media #{$max-width-mobile}{
          width: 27vw;
        }
      }
    }
  }
  .text-block{
    text-align: center;
    font-size: 20px;
    width: 100%;
    max-width: 625px;
    margin: 0 0 15px;
    @media #{$max-width-desktop} {
      max-width: 32.5vw;
      margin-bottom: 0.8vw;
      font-size: 1.03vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      max-width: 60vw;
      margin: 0 auto;
      font-size: 2.5vw;
    }
    @media #{$max-width-mobile}{
      max-width: 100%;
      font-size: 4.5vw;
    }
    p{
      margin: 0 0 1.25em;
      @media #{$max-width-mobile} {
        margin-bottom: 12vw;
      }
    }
    article{
      text-transform: uppercase;
      font-family: $font-family--Plumpfull;
    }
  }
  .img-block{
    position: absolute;
    bottom: 0;
    top: 25px;
    //right: 30px;
    width: 665px;
    left: 50%;
    img{
      display: block;
      width: 100%;
      @media (min-width: 1024px) {
        width: auto;
        height: 100%;
      }
    }
    @media #{$max-width-desktop}{
      width: 38.636vw;
      top: 1.3vw;
      right: 1.5vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      width: 95vw;
      top: auto;
      right: 0;
      margin: 0 auto;
      left: 0;
      bottom: -70vw;
    }
    @media #{$max-width-mobile} {
      width: 136vw;
      margin: 0 auto;
      left: -20.7vw;
      top: 100%;
      height: 64vw;
      overflow: hidden;
      position: relative;
      bottom: 0;
    }
  }
}
.form-registration{
  width: 100%;
  max-width: 555px;
  margin: 0 0 0 35px;
  position: relative;
  [data-reg="true"] &{
    display: none;
    transition-delay: .5s;
  }
  @media #{$max-width-desktop}{
    max-width: 28.91vw;
    margin: 0 0 0 1.8vw;
  }
  @media (max-width: 1024px) and (orientation: portrait){
    max-width: 50vw;
    margin: 0 auto;
  }
  @media #{$max-width-mobile}{
    max-width: 100%;
  }
  @-webkit-keyframes autofill {
    to {
      color: #6F5B4B;
      background-color: transparent;
    }
  }
  input:not([type="radio"]),
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus{
    width: 100%;
    background-image: url("../img/bg-input.png")!important;
    background-size: 100% 100%;
    background-position: left top;
    background-repeat: no-repeat;
    background-color: transparent!important;
    box-sizing: border-box;
    height: 60px;
    border: none;
    outline: none;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 700;
    color: #6F5B4B !important;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    @media #{$max-width-desktop}{
      height: 3.125vw;
      padding: 0 1vw;
      font-size: 1vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      height: 6vw;
      padding: 0 2vw;
      font-size: 2vw;
    }
    @media #{$max-width-mobile}{
      height: 17vw;
      padding: 0 6vw;
      font-size: 6vw;
    }
  }
  input[type="radio"]{
    display: none;
    & + label{
      font-size: 24px;
      font-weight: 700;
      @media #{$max-width-desktop}{
        font-size: 1.25vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        font-size: 2.5vw;
      }
      @media #{$max-width-mobile} {
        font-size: 4.75vw;
      }
      &:before{
        content: $icon-check;
        font-family: 'chiens';
        text-align: center;
        font-size: 16px;
        display: block;
        width: 36px;
        height: 34px;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 100% 100%;
        background-image: url("../img/bg-check.png");
        margin-right: 15px;
        line-height: 34px;
        color: transparent;
        transition-duration: .3s;
        @media #{$max-width-desktop}{
          width: 1.878vw;
          margin-right: 0.8vw;
          height: 1.76vw;
          font-size: 0.83vw;
          line-height: 1.9vw;
        }
        @media (max-width: 1024px) and (orientation: portrait){
          width: 3vw;
          margin-right: 1vw;
          height: 3vw;
          font-size: 1vw;
          line-height: 3vw;
        }
        @media #{$max-width-mobile}{
          width: 6vw;
          height: 6vw;
          line-height: 6vw;
          font-size: 2vw;
          margin-right: 2vw;
        }
      }
    }
    &:checked + label{
      &:before{
        color: #6F5B4B;
        font-size: 26px;
        transition-duration: .3s;
        @media #{$max-width-desktop}{
          font-size: 1.35vw;
        }
        @media (max-width: 1024px) and (orientation: portrait){
          font-size: 2.5vw;
        }
        @media #{$max-width-mobile} {
          font-size: 4.5vw;
        }
      }
    }
  }
  .input-wrapper{
    position: relative;
    &-text{
      background-image: url("../img/bg-input.png")!important;
      background-size: 100% 100%;
      background-position: left top;
      background-repeat: no-repeat;
    }
  }
  .input-block{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    padding-top: 30px;
    @media #{$max-width-desktop}{
      padding-top: 1.6vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      padding-top: 5vw;
    }
    @media #{$max-width-mobile} {
      padding-top: 9vw;
    }
    input{
      &:focus + .label,&.is-value + .label{
        transform: translate(0,-100%);
        top: -8px;
        transition-duration: .3s;
        color: white;
        @media #{$max-width-desktop}{
          top: -0.42vw;
        }
        @media #{$max-width-mobile} {
          font-size: 4vw;
        }
      }
    }
    .label{
      position: absolute;
      color: $color-brown;
      font-weight: 700;
      font-size: 20px;
      top: 50%;
      transform: translate(0,-50%);
      margin-left: 20px;
      pointer-events: none;
      transition-duration: .3s;
      @media #{$max-width-desktop}{
        font-size: 1vw;
        margin-left: 1vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        font-size: 2vw;
        margin-left: 2vw;
      }
      @media #{$max-width-mobile} {
        font-size: 6vw;
        margin-left: 6vw;
      }
    }
    &.active{
      position: relative;
      pointer-events: all;
      opacity: 1;
    }
    &.is-done{
      z-index: 1;
      transform: scale(1.5);
      filter: blur(25px);
      transition-duration: 1s;
    }
    &--check{
      @media #{$max-width-mobile} {
        margin-top: 7vw;
      }
      .inner-wrapper{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 12.5px 0;
        box-sizing: border-box;
        @media #{$max-width-desktop}{
          padding: 0.83vw 0;
        }
      }
      .label{
        transform: none;
        top: 0;
        color: white;
      }
      label{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .input-wrapper{
        margin-left: 20px;
        @media #{$max-width-desktop}{
          margin-left: 1vw;
        }
        @media #{$max-width-mobile} {
          margin-left: 6vw;
        }
        & + .input-wrapper{
          margin-left: 30px;
          @media #{$max-width-desktop}{
            margin-left: 1.5vw;
          }
          @media (max-width: 1024px) and (orientation: portrait){
            margin-left: 3vw;
          }
          @media #{$max-width-mobile} {
            margin-left: 3vw;
          }
        }
      }
      .next-step{
        color: white;
      }
    }
  }
  .next-step{
    font-size: 20px;
    color: $color-brown;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0,-50%);
    &:hover{
      cursor: pointer;
    }
    @media #{$max-width-desktop}{
      right: 1.5vw;
      font-size: 1vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      font-size: 2vw;
      right: 2vw;
    }
    @media #{$max-width-mobile}{
      font-size: 4vw;
      right: 5vw;
    }
  }
  .error-message-block{
    margin-top: 12px;
    color: #CE0000;
    font-style: italic;
    font-size: 16px;
    margin-left: 20px;
    opacity: 0;
    pointer-events: none;
    transition-duration: .3s;
    @media #{$max-width-desktop}{
      font-size: 0.83vw;
      margin-left: 1vw;
      margin-top: 0.65vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      font-size: 2vw;
      margin-left: 2vw;
      margin-top: 1vw;
    }
    @media #{$max-width-mobile} {
      font-size: 4vw;
      margin-left: 6vw;
      margin-top: 2vw;
    }
    &.is-visible{
      transition-duration: .3s;
      opacity: 1;
    }
  }
}
@-moz-document url-prefix() {
  .form-registration{
    input{
      width: 100%;
      background-image: url("../img/bg-input.png")!important;
      background-size: 100% 100%;
      background-position: left top;
      background-repeat: no-repeat;
      background-color: transparent!important;
      box-sizing: border-box;
      height: 60px;
      border: none;
      outline: none;
      padding: 0 20px;
      font-size: 20px;
      font-weight: 700;
      color: #6F5B4B !important;
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
      @media #{$max-width-desktop}{
        height: 3.125vw;
        padding: 0 1vw;
        font-size: 1vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        height: 6vw;
        padding: 0 2vw;
        font-size: 2vw;
      }
      @media #{$max-width-mobile}{
        height: 17vw;
        padding: 0 6vw;
        font-size: 6vw;
      }
    }
  }
}