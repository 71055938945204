.popup{
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  padding: 40px 50px 60px;
  background: #E6DBD3;
  display: none;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.16);
  -webkit-overflow-scrolling: touch;
  @media #{$max-width-mobile} {
    padding: 13vw 5vw 8vw;
  }
  .is-open-popup &{
    filter: blur(0);
  }
  .close{
    width: 25px;
    height: 35px;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 100%;
    color: white;
    text-align: center;
    line-height: 35px;
    font-size: 18px;
    font-family: $font-family--Plumpfull;
    z-index: 1;
    background-image: url("../img/bg-close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-transform: uppercase;
    @media #{$max-width-mobile}{
      top: 3vw;
      right: 3vw;
      width: 8vw;
      height: 8vw;
      line-height: 8.3vw;
      font-size: 3.3vw;
    }
    &:hover{
      cursor: pointer;
      @extend %link-hover-transition;
      //transform: rotate(90deg);
    }
  }
  &--wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 100;
    background: transparentize(white,0.3);
    display: none;
    &-inner{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      overflow: auto;
      @media #{$max-width-mobile}{
        padding: 0;
      }
    }
  }
  &--content{
    box-sizing: border-box;
    width: 100%;
    position: relative;
    color: #6F5B4B;
    >*:last-child{
      margin-bottom: 0;
    }
    >*:first-child{
      margin-top: 0;
    }
  }
  &--title{
    font-size: 63px;
    margin-bottom: 30px;
    line-height: 1.2;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: $font-family--Plumpfull;
    @media #{$max-width-tablet} {
      font-size: 50px;
    }
    @media #{$max-width-mobile}{
      font-size: 8vw;
    }
  }
  h3{
    font-size: 28px;
    text-transform: uppercase;
    margin: 0 0 0.7em;
    margin-top: 2em;
    @media #{$max-width-tablet} {
      font-size: 24px;
    }
    @media #{$max-width-mobile}{
      font-size: 5vw;
    }
  }
  a{
    text-decoration: underline;
    color: #6F5B4B;
    @extend %link-hover-transition;
  }
  p{
    margin: 0 0 1em;
    font-size: 18px;
    line-height: 1.15;
    @media #{$max-width-tablet} {
      font-size: 16px;
    }
    @media #{$max-width-mobile}{
      font-size: 4vw;
    }
  }
}