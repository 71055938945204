@keyframes cookieOpen {
  0%{
    transform: translate(0,-100%);
  }
  100%{
    transform: translate(0,0);
  }
}
@keyframes cookieClose {
  0%{
    transform: translate(0,0);
    opacity: 1;
  }
  100%{
    transform: translate(0,-100%);
    opacity: 0;
  }
}
.cookie-block{
  position: fixed;
  top: 0;
  right: 0;
  box-sizing: border-box;
  color: white;
  background-color: transparentize(black,0.5);
  transform: translate(0,-100%);
  animation: cookieOpen 1s linear forwards;
  animation-delay: 1s;
  z-index: 99;
  &.is-hidden{
    animation: cookieClose 1s linear forwards;
    animation-delay: 0s;
  }
  .container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 35px;
    @media #{$max-width-tablet} {
      padding: 15px;
    }
    @media (max-width: 767px){
      display: block;
    }
  }
  .text-block{
    width: 50%;
    flex-grow: 1;
    font-size: 20px;
    line-height: 1;
    box-sizing: border-box;
    padding-right: 20px;
    font-style: italic;
    @media (max-width: 1920px) {
      font-size: 18px;
    }
    @media (max-width: 1600px) {
      font-size: 16px;
    }
    a{
      display: block;
      color: white;
      text-decoration: underline;
    }
    @media (max-width: 1023px){
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 4vw;
      width: 100%;
      padding: 0;
      margin-bottom: 4vw;
      text-align: justify;
    }
  }
  .link-block{
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 767px){
      text-align: center;
    }
    a{
      font-size: 16px;
      font-weight: 800;
      text-decoration: none;
      width: 170px;
      height: 30px;
      background: white;
      border: 1px solid white;
      color: black;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      line-height: 1;
      @extend %link-hover-transition;
      &:hover{
        background: transparentize(black,0.5);
        color: white;
      }
      @media #{$max-width-tablet}{
        width: 125px;
        font-size: 14px;
      }
      & + a{
        margin-left: 10px;
        @media #{$max-width-mobile}{
          margin-left: 4vw;
        }
      }
      @media #{$max-width-mobile} {
        width: 40%;
        flex-grow: 1;
        height: 10vw;
        font-size: 4vw;
        display: inline-flex;
      }
    }
  }
}
