.warning{
  text-align: center;
  height: 100%;
  height: 100vh;
  padding-top: 30px;
  display: none;
  opacity: 0;
  pointer-events: none;
  @media #{$max-width-mobile} {
    padding-top: 33vw;
    overflow: hidden;
    height: calc(100vh - 24.4vw);
    min-height: 150vw;
  }
  [data-reg="true"] &{
    display: block;
    pointer-events: all;
    animation: fadeIn 700ms linear forwards;
    animation-delay: 200ms;
  }
  [data-quiz="true"] &{
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;
    animation: fadeOut 700ms linear forwards;
  }
  .container{
    height: 100%;
    position: relative;
  }
  .chiens-logo{
    width: 105px;
    position: absolute;
    left: 46%;
    top: -80px;
    display: none;
    transform: translate(-50%,0);
    @media (max-width: 1920px) {
      width: 6vw;
      top: -5vw;
    }
    @media #{$max-width-desktop}{
      width: 12vw;
      top: -12vw;
    }
    @media #{$max-width-mobile}{
      width: 18vw;
      top: -21vw;
      display: block;
    }
  }
  .logos-wrapper{
    width: 400px;
    margin: 0 auto;
    @media #{$max-width-desktop} {
      width: 21vw;
    }
    @media #{$max-width-mobile} {
      width: 85.5vw;
    }
    img{
      width: 100%;
    }
  }
  .text-block{
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translate(0,-50%);
    @media #{$max-width-mobile} {
      top: 50%;
    }
  }
  .icon{
    font-size: 90px;
    margin-bottom: 25px;
    @media #{$max-width-desktop} {
      font-size: 4.7vw;
      margin-bottom: 1.5vw;
    }
    @media #{$max-width-mobile}{
      font-size: 17.5vw;
      margin-bottom: 6vw;
    }
  }
  span{
    font-size: 55px;
    font-family: $font-family--Plumpfull;
    line-height: 1.1;
    text-transform: uppercase;
    @media #{$max-width-desktop} {
      font-size: 2.9vw;
    }
    @media #{$max-width-mobile} {
      font-size: 6vw;
    }
  }
}
