.quiz{
  text-align: center;
  height: 100%;
  display: none;
  opacity: 0;
  pointer-events: none;
  [data-quiz="true"] &{
    display: block;
    pointer-events: all;
    animation: fadeIn 700ms linear forwards;
    animation-delay: 200ms;
  }
  [data-finish="true"] &{
    display: block;
    position: absolute;
    top: 0;
    pointer-events: none;
    animation: fadeOut 700ms linear forwards;
  }
  //[data-device="iPad"] &{
  //  .container{
  //    height: auto;
  //  }
  //}
  &-wrapper{
    height: 100%;
  }
  .container{
    padding: 0;
    height: 100%;
  }
  .regular-logo{
    width: 240px;
    position: absolute;
    top: 30px;
    left: 55px;
    z-index: 1;
    @media #{$max-width-desktop} {
      width: 12.5vw;
      left: 3vw;
      top: 1.5vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      width: 25vw;
    }
    @media #{$max-width-mobile}{
      width: 35vw;
      top: 3vw;
    }
    img{
      width: 100%;
    }
  }
  &-block{
    padding: 160px 35px 0;
    box-sizing: border-box;
    height: 100%;
    opacity: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$max-width-desktop} {
      padding: 8vw 1.8vw 0;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      padding: 25vw 3vw 0;
    }
    @media #{$max-width-mobile}{
      padding-top: 54vw;
      min-height: calc(100vh - 24.3vw);
    }
    &[data-done="true"]{
      display: block;
      position: absolute;
      top: 0;
      z-index: 1;
      pointer-events: none;
      animation: fadeOut 700ms linear forwards;
    }
    &[data-active="false"]{
      display: none;
    }
    &[data-active="true"]{
      display: block;
      pointer-events: all;
      animation: fadeIn 700ms linear forwards;
      animation-delay: 200ms;
    }
    &.is-right{
      .wrong{
        display: none;
      }
    }
    &.is-wrong{
      .right{
        display: none;
      }
    }
  }
  &-title{
    font-family: $font-family--Plumpfull;
    font-size: 80px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 40px;
    @media #{$max-width-desktop}{
      font-size: 4.2vw;
      margin-bottom: 2.1vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      font-size: 6vw;
    }
    @media #{$max-width-mobile}{
      font-size: 13vw;
      margin-bottom: 8vw;
    }
  }
  .input-wrapper{
    @extend %link-hover-transition;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &:hover{
      transform: scale(1.05);
    }
    input{
      display: none;
    }
    label{
      font-size: 36px;
      font-family: $font-family--Plumpfull;
      text-transform: uppercase;
      line-height: 1.09;
      @media #{$max-width-desktop}{
        font-size: 1.9vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        font-size: 3vw;
      }
      @media #{$max-width-mobile}{
        font-size: 4.5vw;
      }
      &:hover{
        cursor: pointer;
      }
    }
  }
  .ou-block{
    color: $color-brown;
    width: 65px;
    height: 65px;
    line-height: 62px;
    font-size: 40px;
    font-family: $font-family--Plumpfull;
    position: relative;
    margin: 0 60px;
    z-index: 0;
    @media #{$max-width-desktop}{
      width: 3.4vw;
      height: 3.4vw;
      font-size: 2vw;
      line-height: 3.3vw;
      margin: 0 3vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      width: 6vw;
      height: 6vw;
      font-size: 3.5vw;
      line-height: 6vw;
      margin: 0 5vw;
    }
    @media #{$max-width-mobile}{
      width: 10vw;
      height: 10vw;
      font-size: 5vw;
      line-height: 10vw;
      margin: 6vw 0;
    }
    .ou-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: translate 8s linear infinite;
      z-index: -1;
      &:before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../img/ou.svg");
        animation: rotate 10s linear infinite;
      }
    }
  }
  &-choice{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    @media #{$max-width-desktop} {
      margin-bottom: 2.6vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      margin-bottom: 10vw;
    }
    @media #{$max-width-mobile} {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  .img-block{
    margin: 0 auto;
    width: 90%;
    @media (max-width: 1024px) and (orientation: portrait){
      width: 136vw;
      position: relative;
      left: -21vw;
    }
    @media #{$max-width-mobile} {
      width: 136vw;
      margin: 0 auto;
      left: -20.7vw;
      top: 100%;
      height: 64vw;
      overflow: hidden;
      position: relative;
      bottom: 0;
    }
    img{
      width: 100%;
    }
  }
  .player{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-image: url("../img/bg-player.svg");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 17px 20px;
    width: 560px;
    margin: 0 auto 25px;
    align-items: center;
    position: relative;
    @media #{$max-width-desktop}{
      padding: 1vw;
      margin-bottom: 1.3vw;
      width: 29vw;
    }
    @media (max-width: 1024px) and (orientation: portrait) {
      padding: 2.1vw 2vw;
      margin-bottom: 3vw;
      width: 50vw;
    }
    @media #{$max-width-mobile}{
      width: 84vw;
      padding: 4vw;
      margin-bottom: 8vw;
    }
    @keyframes arrow {
      0%{
        transform: translate(0,0) rotate(0deg);
      }
      15%{
        transform: translate(-10%,0) rotate(5deg);
      }
      25%{
        transform: translate(-10%,0) rotate(3deg);
      }
      35%{
        transform: translate(0%,0) rotate(0deg);
      }
      50%{
        transform: translate(-10%,0) rotate(3deg);
      }
      70%{
        transform: translate(-10%,0) rotate(5deg);
      }
      80%{
        transform: translate(0,0) rotate(0deg);
      }
      85%{
        transform: translate(-10%,0) rotate(0deg);
      }
      90%{
        transform: translate(-3%,0) rotate(0deg);
      }
      95%{
        transform: translate(-1%,0) rotate(0deg);
      }
      96%{
        transform: translate(0%,0) rotate(0deg);
      }
      97%{
        transform: translate(-3%,0) rotate(0deg);
      }
      98%{
        transform: translate(-1%,0) rotate(0deg);
      }
      99%{
        transform: translate(-2%,0) rotate(0deg);
      }
      100%{
        transform: translate(0,0) rotate(0deg);
      }
    }
    @keyframes arrow-mobile {
      0%{
        transform: translate(0,0) rotate(0deg);
      }
      15%{
        transform: translate(0,-10%) rotate(5deg);
      }
      25%{
        transform: translate(0,-10%) rotate(3deg);
      }
      35%{
        transform: translate(0,0) rotate(0deg);
      }
      50%{
        transform: translate(0,-10%) rotate(3deg);
      }
      70%{
        transform: translate(0,-10%) rotate(5deg);
      }
      80%{
        transform: translate(0,0) rotate(0deg);
      }
      85%{
        transform: translate(0,-10%) rotate(0deg);
      }
      90%{
        transform: translate(0,-3%) rotate(0deg);
      }
      95%{
        transform: translate(0,-1%) rotate(0deg);
      }
      96%{
        transform: translate(0,0) rotate(0deg);
      }
      97%{
        transform: translate(0,-3%) rotate(0deg);
      }
      98%{
        transform: translate(0,-1%) rotate(0deg);
      }
      99%{
        transform: translate(0,-2%) rotate(0deg);
      }
      100%{
        transform: translate(0,0) rotate(0deg);
      }
    }
    &.is-active{
      .arrow-block{
        &:before{
          animation: arrow 3s linear;
          @media #{$max-width-mobile}{
            animation: arrow-mobile 3s linear;
          }
        }
      }
    }
    .arrow-block{
      position: absolute;
      font-size: 30px;
      text-transform: uppercase;
      font-family: $font-family--Plumpfull;
      left: -180px;
      top: -75px;
      pointer-events: none;
      @media #{$max-width-desktop}{
        font-size: 1.5vw;
        left: -9.5vw;
        top: -3.7vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        font-size: 2vw;
        left: -12.5vw;
        top: -3.7vw;
      }
      @media #{$max-width-mobile}{
        font-size: 2.5vw;
        left: 12vw;
        top: -6.7vw;
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        right: 15px;
        width: 80px;
        height: 80px;
        background-image: url("../img/arrow.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        animation: arrow 3s linear infinite;
        @media #{$max-width-desktop}{
          width: 4.17vw;
          height: 4.17vw;
          right: 0.8vw;
        }
        @media (max-width: 1024px) and (orientation: portrait){
          width: 6vw;
          height: 6vw;
        }
        @media #{$max-width-mobile}{
          background-image: url("../img/arrow-mobile.svg");
          width: 8vw;
          height: 6.5vw;
          left: -10vw;
          top: 0;
          right: auto;
          animation: arrow-mobile 3s linear infinite;
        }
      }
    }
    .timeline{
      margin: 0 10px;
      width: 50%;
      height: 12px;
      flex-grow: 1;
      position: relative;
      @media #{$max-width-desktop}{
        height: 0.625vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        height: 1.2vw;
        margin: 0 1vw;
      }
      @media #{$max-width-mobile}{
        height: 3vw;
        margin: 0 3vw;
      }
      svg{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      @media #{$max-width-desktop}{
        margin: 0 0.5vw;
      }
    }
    .play,.replay{
      &:hover{
        cursor: pointer;
      }
    }
    .play{
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../img/play.png");
      transition-duration: .3s;
      width: 25px;
      height: 27px;
      @media #{$max-width-desktop}{
        width: 1.3vw;
        height: 1.4vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        width: 2.3vw;
        height: 2.4vw;
      }
      @media #{$max-width-mobile}{
        width: 4vw;
        height: 4vw;
      }
      &[data-play="true"]{
        background-image: url("../img/pause.png");
        transition-duration: .3s;
      }
    }
    .replay{
      width: 30px;
      height: 32px;
      @media #{$max-width-desktop}{
        width: 1.6vw;
        height: 1.67vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        width: 2.6vw;
        height: 2.67vw;
      }
      @media #{$max-width-mobile}{
        width: 4vw;
        height: 4vw;
      }
      img{
        display: block;
        width: 100%;
      }
    }
    .time-line{
      transition-duration: 0.1s;
    }
  }
  &-block--question{
    [data-answer="true"] &{
      position: absolute;
      top: 0;
      z-index: 1;
      pointer-events: none;
      animation: fadeOut 700ms linear forwards;
      @media #{$max-width-mobile} {
        left: 0;
        width: 100%;
        top: 48vw;
      }
    }
  }
  &-block--img-block{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 55%;
    display: none;
    pointer-events: none;
    opacity: 0;
    img{
      width: 100%;
      display: block;
    }
    [data-answer="true"] &{
      display: block;
      pointer-events: all;
      animation: fadeIn 700ms linear forwards;
      animation-delay: 200ms;
    }
    @media (max-width: 1024px) and (orientation: portrait) {
      width: 100%;
    }
    @media #{$max-width-mobile} {
      width: 160%;
      left: -30%;
    }
  }
  &-block--result{
    margin: 0 auto;
    width: 100%;
    display: none;
    pointer-events: none;
    opacity: 0;
    padding-top: 305px;
    @media #{$max-width-desktop} {
      padding-top: 16vw;
    }
    @media #{$max-width-mobile} {
      padding-top: 28vw;
    }
    [data-answer="true"] &{
      display: block;
      pointer-events: all;
      animation: fadeIn 700ms linear forwards;
      animation-delay: 200ms;
      z-index: 1;
      position: relative;
    }
    .text-block{
      text-align: center;
      position: relative;
      width: 100%;
      max-width: 935px;
      margin: 0 auto;
      z-index: 1;
      font-size: 24px;
      text-shadow: 0px 0px 3px rgb(0, 0, 0);
      @media #{$max-width-desktop}{
        font-size: 1.25vw;
        max-width: 49vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        font-size: 2vw;
        max-width: 75vw;
      }
      @media #{$max-width-mobile}{
        font-size: 4vw;
        max-width: 87vw;
        margin-bottom: 10vw;
      }
    }
    .next-quiz{
      font-size: 26px;
      font-family: $font-family--Plumpfull;
      text-transform: uppercase;
      margin-top: 30px;
      background: none;
      outline: none;
      border: none;
      color: white;
      @media #{$max-width-desktop}{
        font-size: 1.35vw;
        margin-top: 1.5vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        font-size: 2.5vw;
        margin-top: 3vw;
      }
      @media #{$max-width-mobile}{
        font-size: 5.3vw;
        margin-top: 8vw;
      }
      .icon{
        font-size: 80%;
        margin-left: 1em;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .quiz-title{
      margin-bottom: 20px;
      @media #{$max-width-desktop}{
        margin-bottom: 1.1vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        margin-bottom: 2vw;
      }
    }
  }
}
.mask{
  position: absolute;
  left: -999em;
  pointer-events: none;
  opacity: 0;
}
.quiz-step-line{
  position: absolute;
  right: 60px;
  top: 40%;
  font-family: $font-family--Plumpfull;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  transform: translate(0,-50%);
  height: 400px;
  overflow: hidden;
  mask: url(../img/mask2.svg) top left / cover;
  @media #{$max-width-desktop}{
    height: 21vw;
    right: 3vw;
    font-size: 0.9vw;
  }
  @media (max-width: 1024px) and (orientation: portrait){
    height: 40vw;
    right: 4vw;
    font-size: 2vw;
  }
  @media #{$max-width-mobile}{
    left: 0;
    right: 0;
    top: 32vw;
    mask: url(../img/mask3.svg) top left / cover;
    font-size: 3.5vw;
    height: 12vw;
    width: 87vw;
    overflow: hidden;
    margin: 0 auto;
    transform: none;
  }
  @for $i from 1 through 4 {
    &[data-step="#{$i}"]{
      li{
        transform: translate(0, -100% * ($i - 1));
        transition-duration: .7s;
        @media #{$max-width-mobile}{
          transform: translate( -100% * ($i - 1),0);
        }
      }
    }
  }
  &:before{
    content: '';
    width: 100%;
    height: 50%;
    display: block;
    @media #{$max-width-mobile}{
      display: none;
    }
  }
  .numb-block{
    font-size: 26px;
    margin-bottom: 5px;
    @media #{$max-width-desktop}{
      margin-bottom: 0.3vw;
      font-size: 1.35vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      font-size: 3vw;
      margin-bottom: 1vw;
    }
    @media #{$max-width-mobile}{
      font-size: 5.6vw;
      margin-bottom: 1.5vw;
    }
    sup{
      top: -0.5em;
      font-size: 55%;
    }
  }
  ul{
    margin-top: -25px;
    pointer-events: none;
    @media #{$max-width-desktop}{
      margin-top: -1.3vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      margin-top: -2.5vw;
    }
    @media #{$max-width-mobile}{
      margin-top: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: absolute;
      top: 0;
      left: 50%;
      width: 226vw;
      margin-left: -5vw;
    }
  }
  li{
    @media #{$max-width-mobile}{
      padding-right: 42vw;
      position: relative;
    }
    &:after{
      content: '';
      width: 11px;
      height: 200px;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../img/dots.svg");
      display: block;
      margin: 10px auto;
      @media #{$max-width-desktop}{
        width: 0.574vw;
        height: 10.4vw;
        margin: 0.52vw auto;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        width: 1vw;
        height: 20vw;
        margin: 1vw auto;
      }
      @media #{$max-width-mobile}{
        background-image: url("../img/dots2.svg");
        height: 2vw;
        width: 40vw;
        position: absolute;
        right: 1vw;
        top: 50%;
        margin-top: -1vw;
      }
    }
    &:last-child{
      &:after{
        background: none;
      }
    }
  }
}