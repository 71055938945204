@include fontFace('Plumpfull',400, normal);

$width-large: 1920px;
$width-desktop: 1025px;
$width-tablet: 768px;

$max-width-desktop: 'all and (max-width: #{$width-large - 1})';
$max-width-tablet: 'all and (max-width: #{$width-desktop - 1})';
$max-width-mobile: 'all and (max-width: #{$width-tablet - 1})';

$desktop-large: 'all and (min-width: #{$width-large})';
$desktop: 'all and (min-width: #{$width-desktop})';
$tablet: 'all and (min-width: #{$width-tablet})';

$color-brown: #CCBFB6;

$font-family--Asap: 'Asap', sans-serif;
$font-family--OpenSans: 'Open Sans', sans-serif;
$font-family--Plumpfull: 'Plumpfull';

html,
body {
  line-height: 1.35;
  margin: 0;
  font-family: $font-family--Asap;
  min-height: 100vh;
  color: black;
  font-size: 20px;
  height: 100vh;
  overflow: hidden;
  @media #{$max-width-tablet} {
    font-size: 18px;
  }
  @media #{$max-width-mobile} {
    font-size: 4.7vw;
  }
}
html{
  overflow-x: hidden;
  &.is-open-popup{
    overflow: hidden;
    height: 100%;
    max-height: 100%;
    body{
      >*{
        filter: blur(10px);
        transition-duration: .3s;
      }
      .popup--wrapper{
        filter: blur(0);
        transition-duration: 0s;
      }
    }
  }
}
body{
  width: 100%;
  box-sizing: border-box;
  background-image: url("../img/bg-intro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  @media #{$max-width-mobile} {
    overflow: auto;
  }
  &.is-open-popup{
    >*{
      filter: blur(100px);
      transition-duration: .3s;
    }
  }
  &[data-device="iPad"]{
    height: auto;
  }
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  line-height: 1.25;
}

.clearfix{
  @include clearfix;
}

.text-normal{
  font-weight: normal;
}
.text-center{
  text-align: center;
}

b, strong{
  font-weight: 700;
}

a{
  text-decoration: none;
  color: white;
}

ul{
  padding: 0;
  margin: 0;
  li{
    list-style: none;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

button,a,*{
  outline: none;
}

header,footer,section{
  box-sizing: border-box;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0;
  .container{
    padding: 0 35px;
    width: 100%;
    max-width: $width-large;
    margin: 0 auto;
    box-sizing: border-box;
    @media #{$max-width-desktop} {
      padding: 0 3vw;
    }
    @media #{$max-width-mobile} {
      padding: 0 4vw;
    }
  }
}
form{
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden;
  [data-device="iPad"] &{
    width: 1024px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media #{$max-width-mobile} {
    height: auto;
  }
}

img{
  display: block;

}

.mobile-visible{
  @media (min-width: 768px) {
    display: none!important;
  }
}
.mobile-hidden{
  @media (max-width: 767px) {
    display: none!important;
  }
}
.tablet-visible{
  @media (min-width: 1024px) {
    display: none!important;
  }
}
.tablet-hidden{
  @media (max-width: 1023px) {
    display: none!important;
  }
}
.hidden-all{
  display: none!important;
}
@keyframes fadeIn {
  0%{
    filter: blur(100px);
    opacity: 0;
    transform: scale(1.1);
  }
  100%{
    filter: blur(0);
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    filter: blur(0);
    opacity: 1;
    transform: scale(1);
  }
  100% {
    filter: blur(100px);
    opacity: 0;
    transform: scale(1.1);
    pointer-events: none;
  }
}

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg)
  }
}
@keyframes translate {
  0%{
    transform: translate(2%, 3%);
  }
  15%{
    transform: translate(4%, 0%);
  }
  30%{
    transform: translate(1%, 2%);
  }
  60%{
    transform: translate(3%, 5%);
  }
  100%{
    transform: translate(2%, 3%);
  }
}