.merci{
  height: 100%;
  padding-top: 110px;
  opacity: 0;
  display: none;
  @media (max-width: 1600px){
    padding-top: 5.5vw;
  }
  @media (max-width: 1366px) {
    padding-top: 8vw;
  }
  @media #{$max-width-mobile} {
    padding-top: 24vw;
  }
  [data-finish="true"] &{
    display: block;
    pointer-events: all;
    animation: fadeIn 700ms linear forwards;
    animation-delay: 200ms;
  }
  .regular-logo{
    @media #{$max-width-mobile}{
      position: absolute;
      z-index: 1;
      width: 35vw;
      top: 3vw;
      left: 3vw;
    }
    img{
      width: 100%;
    }
  }
  .container{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    max-width: 1440px;
    @media (max-width: 1919px){
      max-width: 75vw;
    }
    @media #{$max-width-mobile}{
      max-width: 100%;
      flex-direction: column-reverse;
    }
  }
  .content-block{
    text-align: center;
    width: 100%;
    max-width: 680px;
    @media (max-width: 1920px){
      max-width: 35.45vw;
    }
    @media (max-width: 1024px) and (orientation: portrait) {
      max-width: 80vw;
    }
    @media #{$max-width-mobile}{
      max-width: 100%;
      margin-bottom: 12vw;
    }
  }
  .img-block{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 700px;
    @media (max-width: 1920px) {
      width: 36.5vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      margin-top: -45vw;
      margin-left: -4vw;
      width: 80vw;
      bottom: 0;
      top: 100%;
    }
    @media #{$max-width-mobile} {
      width: 136vw;
      margin: 0 auto;
      left: -20.7vw;
      top: 100%;
      height: 64vw;
      overflow: hidden;
      position: relative;
      bottom: 0;
    }
    img{
      width: 100%;
      display: block;
    }
  }
  .result-block{
    text-transform: uppercase;
    font-size: 28px;
    color: $color-brown;
    font-weight: bold;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../img/bg-count.png");
    text-align: center;
    box-sizing: border-box;
    display: inline-flex;
    width: 400px;
    height: 75px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    @media (max-width: 1920px){
      width: 20.834vw;
      height: 3.91vw;
      font-size: 1.45vw;
      margin-bottom: 0.5vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      width: 45vw;
      height: 7vw;
      font-size: 3vw;
      margin-bottom: 2vw;
    }
    @media #{$max-width-mobile}{
      width: 75vw;
      height: 10.67vw;
      font-size: 5.7vw;
      margin-bottom: 3vw;
    }
  }
  &--title{
    font-family: $font-family--Plumpfull;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 0.9;
    margin-bottom: 15px;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    @media (max-width: 1919px){
      font-size: 1.3vw;
      margin-bottom: 0.8vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      font-size: 3vw;
      margin-bottom: 4vw;
    }
    @media #{$max-width-mobile}{
      font-size: 4.6vw;
      margin-bottom: 13.3vw;
    }
    .big{
      font-size: 525%;
    }
  }
  &--text{
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 25px;
    @media (max-width: 1919px){
      font-size: 1vw;
      margin-bottom: 1vw;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      font-size: 2vw;
      margin-bottom: 2vw;
    }
    @media #{$max-width-mobile}{
      font-size: 4.3vw;
      margin-bottom: 10vw;
    }
    p{
      margin: 0;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    }
  }
  .social-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    @media (max-width: 1919px){
      margin: 0.8vw 0;
    }
    @media (max-width: 1024px) and (orientation: portrait){
      margin: 2vw 0;
    }
    @media #{$max-width-mobile}{
      margin: 5vw 0;
    }
    li{
      margin-right: 15px;
      @media (max-width: 1920px) {
        margin-right: 0.8vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        margin-right: 3.5vw;
      }
      &:last-child{
        margin-right: 0;
      }
      @for $i from 1 through 3 {
        &:nth-child(#{$i}){
          a{
            transform: rotate(30deg * $i);
            .icon{
              transform: rotate(-30deg * $i);
            }
          }
        }
      }
    }
    a{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 60px;
      height: 60px;
      z-index: 0;
      font-size: 34px;
      color: #CAB2A2;
      @media (max-width: 1920px){
        width: 3.13vw;
        height: 3.13vw;
        font-size: 1.8vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        width: 6vw;
        height: 6vw;
        font-size: 3vw;
      }
      @media #{$max-width-mobile}{
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
      }
    }
    .bg-block{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: translate 8s linear infinite;
      z-index: -1;
      &:before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../img/ou.svg");
        animation: rotate 10s linear infinite;
      }
    }
  }
  &--don{
    a{
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 28px;
      font-family: $font-family--Plumpfull;
      width: 320px;
      height: 60px;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../img/bg-button.png");
      text-transform: uppercase;
      @media (max-width: 1920px){
        font-size: 1.45vw;
        width: 16.67vw;
        height: 3.13vw;
      }
      @media (max-width: 1024px) and (orientation: portrait){
        font-size: 3vw;
        width: 37vw;
        height: 7vw;
      }
      @media #{$max-width-mobile}{
        font-size: 5.4vw;
        width: 64vw;
        height: 12vw;
      }
    }
  }
}
